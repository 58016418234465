<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-layout wrap>
      <v-container >
        <v-window v-model="step" >
          <v-window-item :value="1">
       <v-row class="blue "> 
         <div style="  text-align: center; padding: 20px ">
              <v-col cols="12" md="12" >
                  <v-card-text class="white--text">
                    <h1 class="text-center">MCSYSTEMS</h1>
                    <h6 class="text-center blue--text">
                      Inicie sesión en su cuenta para que pueda continuar
                    </h6>
                  </v-card-text>
                  
              </v-col>
         </div>
               </v-row>
            <v-card>   
            <v-row> 
            <v-col cols="12" md="12" >
              <h1 class="text-center">Ingrese su Usuario</h1>
              <h4 class="text-center grey--text">
                Inicie sesión en su cuenta para seguir
              </h4>
              <v-row align="center" justify="center">
                <v-col cols="12" sm="8">
                  <v-text-field
                    label="Email"
                    outlined
                    dense
                    color="blue"
                    autocomplete="false"
                    class="mt-16"
                    v-model="email"
                  />

                  <v-text-field
                    :append-icon="show1 ? 'visibility' : 'visibility_off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    outlined
                    dense
                    color="blue"
                    autocomplete="false"
                    counter
                    @click:append="show1 = !show1"
                    @keyup.enter="ingresar()"
                    v-model="password"
                  >
                  </v-text-field>

                  <v-row>
                    <v-col cols="12" sm="7">
                      <v-checkbox
                        label="Recordarme"
                        class="mt-n1"
                        color="blue"
                        v-model="recordarme"
                      >
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <span class="red--text">{{ this.error }}</span>
                    </v-col>
                  </v-row>
                  <div class="text-center">
                    <v-btn
                      :disabled="dialog"
                      :loading="dialog"
                      block
                      class="white--text"
                      color="blue"
                      @click="(dialog = true), ingresar()"
                    >
                      Ingresar
                    </v-btn>
                   
                    <v-row> 
                        <v-card-text class="blue--text">
                          <h3 class="text-center">
                            No Recuerdas tu Contraseña?
                          </h3>
                          <h6 class="text-center">
                            Vamos a Recuperar la Contraseña
                          </h6>
                          <v-btn
                      tile outlined block dark @click="step++" color="blue"
                    >
                      Recuperar
                    </v-btn>
                        </v-card-text>
                      
                    </v-row>

                    </div>
                    <v-dialog
                      v-model="dialog"
                      hide-overlay
                      persistent
                      width="600"
                    >
                      <v-card color="primary" dark>
                        <v-card-text>
                          Please stand by
                          <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                          ></v-progress-linear>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                </v-col>
              </v-row>
            </v-col>
            </v-row>
            </v-card>
          </v-window-item>

          <v-window-item :value="2">
            <v-row class="blue rounded-br-xl"> 
              <v-col cols="12" md="12" >
                  <v-card-text class="white--text">
                    <h3 class="text-center">Listo para Iniciar</h3>
                    <h6 class="text-center">
                      Inicie sesión en su cuenta para que pueda continuar
                    </h6>
                  </v-card-text>
                  <div class="text-center">
                    <v-btn tile outlined dark @click="step--">Iniciar</v-btn>
                  </div>
              </v-col>
               </v-row>
                <v-row>
                <v-card-text class="mt-12">
                  <h4 class="text-center">Completa para Recuperar</h4>
                  <h6 class="text-center grey--text">
                    Vamos a configurarlo todo para que pueda recuperar su cuenta
                  </h6>
                  <v-row align="center" justify="center">
                    <v-col cols="12" sm="8">
                      <v-row>
                        <v-col cols="6" sm="6">
                          <v-text-field
                            label="Nombre"
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                            class="mt-4"
                          />
                        </v-col>
                        <v-col cols="6" sm="6">
                          <v-text-field
                            label="Apeido"
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                            class="mt-4"
                          />
                        </v-col>
                      </v-row>

                      <v-text-field
                        label="Email"
                        outlined
                        dense
                        color="blue"
                        autocomplete="false"
                        v-model="email"
                      />

                      <v-row>
                        <v-col cols="12" sm="7"> </v-col>
                      </v-row>
                      <v-btn 
                      :disabled="dialog"
                      :loading="dialog"
                      color="blue" dark block tile @click="precuperar()"
                        >Recuperar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              
            </v-row>
          </v-window-item>
        </v-window>
      </v-container>

      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10">
            <v-card class="elevation-6 mt-10"> </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </v-app>
</template>


<script>
import axios from "axios";
import { Base64 } from "js-base64";

export default {
  data() {
    return {
      email: "",

      error: null,
      recuperar: "",
      texto: "",
      loading: false,
      show1: false,
      recordarme: false,
      show2: true,
      show3: false,
      show4: false,
      password: "",
      dialog: false,
      step: 1,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  watch: {
    /*     dialog (val) {
      if (!val) return

      setTimeout(() => (this.dialog = false), 4000)
    },
 */
  },
  methods: {
    mostrarrecuperar() {
      this.recuperar = 1;
    },

    Esperarcargar() {
      this.dialog = true;
    },
    Esperartermina() {
      this.dialog = null;
    },

    setCookie(cName, value, expireDays) {

      let exDate = new Date();
      exDate = exDate.setDate(exDate.getDate() + expireDays);
      document.cookie =
        cName +
        "=" +
        value +
        (expireDays === undefined
          ? ""
          : ";expires=" + new Date(exDate).toUTCString());
    },

    getCookie(key) {
      if (document.cookie.length > 0) {
        let start = document.cookie.indexOf(key + "=");
        if (start !== -1) {
          start = start + key.length + 1;
          let end = document.cookie.indexOf(";", start);

          if (end === -1) {
            end = document.cookie.length;
            return Base64.decode(document.cookie.substring(start, end));
          } else {
            return document.cookie.substring(start, end);
          }
        }
      }
      return "";
    },

    ocultarrecuperar() {
      this.recuperar = "";
    },

    ingresar() {
      this.Esperarcargar();
      if (this.email.length <= 5 || this.password.length <= 2) {
        this.error = "Verificar sus Datos";
        this.Esperartermina();
      } else {
        this.error = null;
        axios
          .post("api/Usuarios/Login", {
            email: this.email,
            password: this.password,
          })
          .then((respuesta) => {
            return respuesta.data;
          })
          .then((data) => {
            if (this.recordarme == true) {
              
              this.setCookie("username", this.email, 30);
              const psd = Base64.encode(this.password);
              this.setCookie("psd", psd, 30);
            }
            this.$store.dispatch("guardarToken", data.token);
            this.$router.push({ name: "Home" });
          })
          .finally(() => this.Esperartermina())
          .catch((err) => {
            //console.log(err.response);
            if (err.response.status > 0) {
              if (err.response.status == 400) {
                this.error = "No es un email válido";
              }
              if (err.response.status == 404) {
                this.error = "No existe el usuario o sus datos son incorrectos";
              }
              if (err.response.status == 418) {
                this.error = "Usuario Bloqueado";
              }
            } else {
              this.error = "Ocurrió un error";
            }
            //console.log(err)
          })
          .finally(() => this.Esperartermina());
      }
    },

    precuperar() {
      this.Esperarcargar();
      if (this.email.length <= 5) {
        alert("Verificar sus Datos");
         this.Esperartermina();
        return;
      } else {
        this.error = null;
        axios
          .put("api/Usuarios/RecuperarPWS/" + this.email, {})
          .then((respuesta) => {
            return respuesta.data;
          })
          .then((data) => {
            this.ocultarrecuperar();
            alert("Se envio un Correo con su contraseña");
            // this.$store.dispatch("guardarToken", data.token)
            // this.$router.push({ name: 'Home' })
          })
          .finally(() => this.Esperartermina())
          .catch((err) => {
            //console.log(err.response);
            this.ocultarrecuperar();
            alert("Se envio un Correo con su contraseña");
            //this.error = "Se envio un Correo con su contraseña";
          }).finally(() => this.Esperartermina());
      }
    },

    initForm() {
      const username = this.getCookie("username");
      const psd = this.getCookie("psd");
      if (username && psd) {
        this.email = username;
        this.password = psd;
      }
    },
  },
  created() {
    this.initForm();
  },
};
</script>
<style scoped>
.rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
}

.rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
</style>