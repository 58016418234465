<template>
    <v-data-table
      :headers="headers"
      :items="ubicaciones"
      sort-by="Idubicacion"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Ubicacion</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-btn @click="crearPDF()"><v-icon>print</v-icon></v-btn>
                        
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Nuevo
              </v-btn>
            
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>

                  <v-row>
                      <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.Idubicacion"
                        label="IdUbicacion"
                        :disabled="campo_bloqueado">
                      ></v-text-field>

                    </v-col >

                    <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.Nombre"
                        label="Nombre"
                      ></v-text-field>

                    </v-col >


                       <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                    
  </v-col>
                       <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                    <img id='itf' >
                     
                    </v-col >
                      <v-btn @click="generarpdf(editedItem.Codigo)" ><v-icon>print</v-icon></v-btn>
  
                    <v-col

                      cols="12"
                      sm="6"
                      md="12"
                      v-show="valida"
                    >
                        <v-text class="red--text" v-for="v in validamensaje" :key="v" v-text="v">                    
                        </v-text>
                      
                    </v-col>
                  
                  </v-row>

                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="Guardar"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
          
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="addmodal" max-width="500px">
            <v-card>
              <v-card-title class="headline" v-if="addaction==1"> Desea Activar </v-card-title>
              <v-card-title class="headline" v-if="addaction==2"> Desea Desactivar </v-card-title>
              
              <v-card-text > Estas a Punto de: </v-card-text>
                <span v-if="addaction==1"> Activar </span>
                <span  v-if="addaction==2"> Desactivar</span>
                el item {{addnombre}}
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="activardesactivarcerrar">Cancel</v-btn>
                  <v-btn v-if="addaction==1" color="blue darken-1" text @click="activar">Activar</v-btn>
                  <v-btn v-if="addaction==2" color="blue darken-1" text @click="desactivar">Desactivar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
           
      
      <template v-slot:[`item.actions`]="{ item }">  
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
        <template v-if="item.condicion">
          <v-icon
            small
            
            @click="activardesacticarmostrar(2,item)"
          >
            block
          </v-icon>    
        </template>
        <template v-else>
          <v-icon
            small
            @click="activardesacticarmostrar(1,item)"
          >
            check
          </v-icon>    
        </template>

      </template>

     <template v-slot:[`item.condicion`]="{ item }">
        <div v-if="item.condicion">
          <span class="blue--text">Activo</span>
        </div>
        <div v-else>
          <span class="red--text">Inactivo</span>
        </div>
      </template>

      <template v-slot:[`item.CodigoBarra`]="{ item }">
           <v-icon
          small
          class="mr-2"
          @click="generarpdf2(item.codigo)"
          
        >
          print
        </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="listar"
        >
          Reset
        </v-btn>
      </template>
      
    </v-data-table>
    
</template>


<script>
import axios from 'axios'
import VueBarcode from 'vue-barcode';
import jsPDF from "jspdf";

export default {

  data: () => ({
    dialog: false,
    search:'',
    dialogDelete: false,
    ubicaciones:[],
    headers: [
      { text: 'Acciones', value: 'actions', sortable: false },
      {
        text: 'Idubicacion',
        align: 'start',
        value: 'idubicacion',
      },
      { text: 'Nombre', value: 'nombre' },
      { text: 'Codigo', value: 'codigo' },
      { text: 'CodigoBarra', value: 'CodigoBarra' },
      { text: 'Condicion', value: 'condicion' }
    ],

    barcodeValue: 'test',
    editedIndex: -1,
    editedItem: {
      Idubicacion: '',
      Nombre: '',
      Codigo: ''
    
    },
      valida:0,
      validamensaje:[],
      addmodal:0,
      addaction:0,
      addnombre:'',
      adid:'',
      campo_bloqueado:false
  }),

 computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nueva Ubicacion' : 'Editando Ubicacion'
    },
    
  },
   components: {
    'barcode': VueBarcode
  },

  created () {
    this.listar()
  },

   watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
    methods: {
        listar(){
                let me=this
                let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};
                axios.get('api/ubicaciones/Listar',configuracion).then(function(response){
                    me.ubicaciones=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },

   

    editItem (item) {
      this.editedItem.Idubicacion = item.idubicacion
      this.editedItem.Nombre = item.nombre
      this.editedItem.Codigo = item.codigo
      //this.editedItem.categoria=item.categoria
      this.editedIndex=1;
      this.campo_bloqueado=true;
      this.dialog = true
      //this.generarcb()
    },

crearPDF() {
      var columns = [
        { title: "Condicion", dataKey: "condicion" },
        { title: "Codigo", dataKey: "codigo" },
        { title: "imagen", dataKey: 5 }
      ];
      var rows = [];
      var images = [];

      this.ubicaciones.map(function (x) {
        rows.push({
          condicion: x.condicion,
          codigo: x.codigo,
        });
      });  

    var doc=new jsPDF('p', 'mm', 'a4');
    doc.page=1;
    var a=6
    var b=7
    var c=110
    var d=40
    doc.setFontSize(20);

      for (var i=0; i<rows.length;i++) {

        console.log(rows.length)  
        console.log(i)            
        

        var base64Str =this.textToBase64Barcode(rows[i].codigo);
        var img = new Image()
        img.src=base64Str
        console.log(base64Str)
        doc.text('UBICACION', a, b); //string, x-position, y-position
        b=b+1
        doc.addImage(img.src, 'png' ,a, b, c, d);
        b=b+45
        if (b >= 200)
          {
          doc.addPage();
            b = 7 // Restart height position
          }       
    }
    doc.save('Ubicacion.pdf');
      //doc.autoPrint();
      //doc.output('dataurlnewwindow');
    },

textToBase64Barcode(text){
  var canvas = document.createElement("canvas");
  JsBarcode(canvas, text, {format: "CODE39"});
  return canvas.toDataURL("image/png");
},




impromircodigo(item){
  var codigo = item.codigo
    alert(codigo)
      JsBarcode("#itf",codigo, {format: "CODE39"});
      var doc = new jsPDF();
      doc.setFontSize(40);
      doc.text(30, 20, 'UBICACION');
      doc.addImage(JsBarcode("#itf",codigo, {format: "CODE39"}), 'JPEG', 7, 20, 90, 80);
      doc.save('Ubicacion.pdf');
},

    generarpdf(text){
      JsBarcode("#itf",text, {format: "CODE39"});
      const img = document.querySelector('img#itf');
      var doc=new jsPDF('p', 'mm', [130, 200]);
      doc.setFontSize(20);
      doc.text('UBICACION', 1, 20); //string, x-position, y-position

      doc.addImage(img.src, 'JPEG' ,1, 20, 110, 40);
      //doc.save('Ubicacion.pdf');
       doc.autoPrint();
      doc.output('dataurlnewwindow');
    },

    generarpdf2(text){


var base64Str =this.textToBase64Barcode(text);
                
console.log(base64Str)

      var doc=new jsPDF('p', 'mm', [130, 200]);
      doc.setFontSize(20);
      doc.text('UBICACION', 1, 20); //string, x-position, y-position
      doc.addImage(base64Str, 'png' ,1, 20, 110, 40);
      //doc.save('Ubicacion.pdf');
       doc.autoPrint();
      doc.output('dataurlnewwindow');


//print (img.src)
      //JsBarcode("item.idubicacion",text, {format: "CODE39"});
      
     
      
    },

    

    generarcb() {
      alert(this.editedItem.Codigo)
      JsBarcode("#itf",this.editedItem.Codigo, {format: "CODE39"});
      const img = document.querySelector('img#itf');
      var doc = new jsPDF();
      doc.setFontSize(40);
      doc.text(30, 20, 'UBICACION');
      doc.addImage(img.src, 'JPEG', 15, 40, 180, 160);
      //doc.save('my.pdf');
    },

    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false;
      this.limpiar();
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    limpiar () {
      this.editedIndex=-1;
      this.editedItem.Nombre="";
      this.editedItem.Idubicacion="";
      //this.editedItem.categoria="";
      this.campo_bloqueado=false;
    },

    

    validar () {
      this.valida=0;
      this.validamensaje=[];

      if (this.editedItem.Nombre.length<3 || this.editedItem.Nombre.length>50 )
      {
          this.validamensaje.push("El nombre debe tener mas de 3 Caracteres y Menos de 50 caracteres")
      }

      if(this.editedItem.categoria.length!=5 )
      {
        this.validamensaje.push("El Codigo debe tener 5 Caracteres")
      }


      if (this.validamensaje.length)
      {
        this.valida=1;
      }
      return this.valida;
    },

 activardesacticarmostrar (accion,item) {
      this.addmodal=1;
      this.addnombre=item.nombre;
      this.adid=item.idubicacion;
      if (accion==1)
      {
        this.addaction=1;
      }
      else if(accion==2) {
        this.addaction=2;
      }
      else{
        this.addmodal=0;
      }
  
    },



activar () {
  let me=this;
  let header={"Authorization":"Bearer "+this.$store.state.token};
  let configuracion={headers:header};
      axios.put('api/Ubicaciones/Activar/'+this.adid,{},configuracion).then(function(response)
          {
            me.addmodal=0;
            me.addaction=0;
            me.addnombre="";
            me.adid="";
            me.listar();

          }).catch(function(error)
          {
            console.log(error);
          });
  
    },

desactivar () {
  let me=this;
  let header={"Authorization":"Bearer "+this.$store.state.token};
  let configuracion={headers:header};
      axios.put('api/Ubicaciones/DesActivar/'+this.adid,{},configuracion).then(function(response)
          {
            me.addmodal=0;
            me.addaction=0;
            me.addnombre="";
            me.adid="";
            me.listar();

          }).catch(function(error)
          {
            console.log(error);
          });
  
    },

activardesactivarcerrar () {
      this.addmodal=0;
    },




Guardar () {
      let ma=this;
      if (ma.validar()){
        return; 
      }

      if (this.editedIndex > -1) {
        //actualizar
        let me=this.editedItem;
        let header={"Authorization":"Bearer "+this.$store.state.token};
        let configuracion={headers:header};
        axios.put('api/ubicaciones/Actualizar',{
          'IdCategoria':me.Idcategoria,
          'Nombre':me.Nombre          
          },configuracion).then(function(response)
            {
              ma.close();
              ma.listar();
              ma.limpiar()
            }).catch(function(error)
            {
              console.log(error);
            });


      } else {
        let me=this.editedItem;
        axios.post('api/Categorias/Crear',{'Nombre':me.Nombre,'categoria':me.categoria},configuracion).then(function(response)
            {
              ma.close()
              ma.listar()
              ma.limpiar()  
            }).catch(function(error)
            {
              console.log(error);
            });
      }   
    },
  }, 
}

</script>


<style scoped>
svg {
  border: 1px solid green;
}

img {
  border: 1px solid red;
}
</style>