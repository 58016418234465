<template>
  <v-layout align-start>
    <v-container grid-list-md>
      <v-layout wrap>

        <v-col>
          <v-row>
            <v-card :loading="loading" max-width="374">
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img
                height="250"
                src="https://mcsystem.inversionesenergy.com/Imagenes/pexels-photo-5668858.webp"
              ></v-img>

              <v-card-title>Fomulario Solicitud </br> Linea de Credito (F101)</v-card-title>

              <v-card-text>
                <v-row align="center" class="mx-0"> </v-row>
                <div>
                  <p>Automatice su proceso de solicitud de crédito para aprobar líneas de crédito más rápido</p>
                </div>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-card-actions>
                <v-btn color="deep-purple lighten-2" text href="../Formatos/Solicitud_Credito_Energy.pdf">
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-col>
        
        <v-col>
          <v-row>
            <v-card :loading="loading" max-width="374">
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img
                height="250"
                src="https://mcsystem.inversionesenergy.com/Imagenes/pexels-photo-9064307.jpeg"
              ></v-img>

              <v-card-title>Fomulario Conoce tu Cliente </br> Juridico (F102)</v-card-title>

              <v-card-text>
                <v-row align="center" class="mx-0"> </v-row>
                <div>
                  <p>Diseñado con el propósito de que el usuario introduzca datos estructurados
                    (nombre, apellidos, dirección, etc.) en las zonas del documento destinadas a 
                    ese propósito, para ser almacenados y procesados posteriormente.</p>
                </div>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-card-actions>
                <v-btn color="deep-purple lighten-2" text href="../Formatos/FORMULARIO_CONOCE_CLIENTE_JURIDICO.pdf">
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-col>

        <v-col>
          <v-row>
            <v-card :loading="loading" max-width="374">
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img
                height="250"
                src="https://mcsystem.inversionesenergy.com/Imagenes/pexels-photo-9064307.jpeg"
              ></v-img>

              <v-card-title>Formulario Conoce tu Cliente </br> Natural (F103)</v-card-title>

              <v-card-text>
                <v-row align="center" class="mx-0"> </v-row>
                <div>
                  <p>Diseñado con el propósito de que el usuario introduzca datos estructurados
                    (nombre, apellidos, dirección, etc.) en las zonas del documento destinadas a 
                    ese propósito, para ser almacenados y procesados posteriormente.</p>
                </div>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-card-actions>
                <v-btn color="deep-purple lighten-2" text href="../Formatos/FORMULARIO_CONOCE_CLIENTE_NATURAL.pdf">
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-col>

        <v-col>
          <v-row>
            <v-card :loading="loading" max-width="374">
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img
                height="250"
                src="https://mcsystem.inversionesenergy.com/Imagenes/pexels-photo-7191154.webp"
              ></v-img>

              <v-card-title>Formulario de Permisos </br>y Vacaciones (F201)</v-card-title>

              <v-card-text>
                <v-row align="center" class="mx-0"> </v-row>
                <div>
                 <p> Formulario para empleados cuando necesite solicitar un permiso o vacaciones</p>
                </div>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-card-actions>
                <v-btn color="deep-purple lighten-2" text  href="../Formatos/SOLICITUD_DE_PERMISO.pdf">
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-col>
     
        <v-col>
          <v-row>
            <v-card :loading="loading" max-width="374"  >
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="200"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img
                height="250"
                src="https://mcsystem.inversionesenergy.com/Imagenes/pexels-photo-4386339.webp"
              ></v-img>

              <v-card-title>Formulario de Solicitud </br> Viaticos (F202)</v-card-title>

              <v-card-text>
                <v-row align="center" class="mx-0"> </v-row>
                <div>
                 <p> Presupuesto asignado al colaborador antes de realizar su viaje. Gastos de viaje</p>
                </div>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-card-actions>
                <v-btn color="deep-purple lighten-2" text href="../Formatos/FORMAT_ DE_VIATICOS.xlsx">
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-col>
     
       <v-col>
          <v-row>
            <v-card :loading="loading" max-width="374"  >
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="200"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img
                height="250"
                src="https://mcsystem.inversionesenergy.com/Imagenes/pexels-photo-14651.webp"
              ></v-img>

              <v-card-title>Hoja de garantía</br>de Maquinaria (F301)</v-card-title>

              <v-card-text>
                <v-row align="center" class="mx-0"> </v-row>
                <div>
                 <p> Terminos y condiciones para la garantia de maquinaria usada vendida</p>
                </div>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-card-actions>
                <v-btn color="deep-purple lighten-2" text href="../Formatos/GARANTIA_EQUIPO_ENERGY.pdf">
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-col>
      </v-layout>
    </v-container>
  </v-layout>
</template>
  


<script>
import axios from "axios";
export default {
  data: () => ({
    dialog: false,
    search: "",
    dialogDelete: false,
    roles: [],
    headers: [
      {
        text: "IdCiudad",
        align: "start",
        value: "idciudad",
      },
      { text: "Nombre", value: "nombre" },
      { text: "Departamento", value: "ndepartameto" },
      { text: "Condicion", value: "condicion" },
    ],
    editedIndex: -1,
    editedItem: {
      Idcategoria: "",
      Nombre: "",
      categoria: "",
    },
  }),

  computed: {},

  created() {
    this.listar();
  },

  watch: {},
  methods: {
    listar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/Ciudades/Listar", configuracion)
        .then(function (response) {
          me.roles = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>