<template>
  <v-data-table
    :headers="headers"
    :items="BanConcepto"
    sort-by="IdConcepto"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Conceptos Bancarios</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Nuevo
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col v-show="editedIndex" cols="12" sm="6" md="12">
                    <v-text-field
                      label="Codigo"
                      v-model="editedItem.Concepto"
                      v-on:blur="validadcodigo()"
                      :disabled="campo_bloqueado"
                    >
                      ></v-text-field
                    >
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.Nombre"
                      label="Nombre"
                    ></v-text-field>
                  </v-col>

                     <v-col cols="12" sm="6">
                    <v-select
                      v-model="editedItem.Tipo"
                      :items="tipodoc"
                      label="Tipo"
                    >
                    </v-select>
                  </v-col>
              <v-col cols="12" sm="6">
<v-checkbox
      v-model="editedItem.Cheque"
      label="Emitir Cheque"
    ></v-checkbox>

              </v-col>
                 <v-col cols="12" sm="12">
                    <v-autocomplete
                      v-model="editedItem.CtaContable"
                      :items="CtaContables"
                      label="Cuenta Contable"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="12" v-show="valida">
                    <v-text
                      class="red--text"
                      v-for="v in validamensaje"
                      :key="v"
                      v-text="v"
                    >
                    </v-text>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="dialogespera"
                :loading="dialogespera"
                @click="(dialogespera = true), Guardar()"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="addmodal" max-width="500px">
          <v-card>
            <v-card-title class="headline" v-if="addaction == 1">
              Desea Activar
            </v-card-title>
            <v-card-title class="headline" v-if="addaction == 2">
              Desea Desactivar
            </v-card-title>

            <v-card-text> Estas a Punto de: </v-card-text>
            <span v-if="addaction == 1"> Activar </span>
            <span v-if="addaction == 2"> Desactivar</span>
            el item {{ addnombre }}
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="activardesactivarcerrar"
                >Cancel</v-btn
              >
              <v-btn
                v-if="addaction == 1"
                color="blue darken-1"
                text
                @click="activar"
                >Activar</v-btn
              >
              <v-btn
                v-if="addaction == 2"
                color="blue darken-1"
                text
                @click="desactivar"
                >Desactivar</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> edit </v-icon>
      <template v-if="item.condicion">
        <v-icon small @click="activardesacticarmostrar(2, item)">
          block
        </v-icon>
      </template>
      <template v-else>
        <v-icon small @click="activardesacticarmostrar(1, item)">
          check
        </v-icon>
      </template>
    </template>

    <template v-slot:[`item.condicion`]="{ item }">
      <div v-if="item.condicion">
        <span class="blue--text">Activo</span>
      </div>
      <div v-else>
        <span class="red--text">Inactivo</span>
      </div>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="listar"> Reset </v-btn>
    </template>
  </v-data-table>
</template>


<script>
import axios from "axios";
export default {
  data: () => ({
    dialog: false,
    search: "",
    dialogDelete: false,
    BanConcepto: [],
    CtaContables: [],
    headers: [
      { text: "Acciones", value: "actions", sortable: false },
      {
        text: "Id Concepto",
        align: "start",
        value: "idConcepto",
      },
      { text: "Codigo", value: "concepto" },
      { text: "Nombre", value: "nombre" },
      { text: "Tipo", value: "tipo" },
      { text: "CtaContable", value: "ctaContable" },
      { text: "Condicion", value: "condicion" },
    ],
     
    

 tipodoc: [
      { text: "Debito", value: 1, sortable: false },
      { text: "Credito", value: 2 }
    ],

    dialogespera: false,
    editedIndex: -1,
    editedItem: {
      IdConcepto: "",
      Concepto: "",
      Nombre: "",
      Tipo: "",
      CtaContable: "",
      Cheque: "",
    },
    valida: 0,
    validamensaje: [],
    addmodal: 0,
    addaction: 0,
    addnombre: "",
    adid: "",
    campo_bloqueado: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Concepto Bancario"
        : "Editando Concepto Bancario";
    },
  },

  created() {
    this.listar();
     this.ListarCtaContables();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    listar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/BanConceptos/Listar", configuracion)
        .then(function (response) {
          me.BanConcepto = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    Esperarcargar() {
      this.dialogespera = true;
    },
    Esperartermina() {
      this.dialogespera = null;
    },

      ListarCtaContables() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      var clasificacionesaray = [];
      axios
        .get("api/CtaContables/listar", configuracion)
        .then(function (response) {
          clasificacionesaray = response.data;
          clasificacionesaray.map(function (x) {
            me.CtaContables.push({ 
              "text": x.ctacontable, 
              "valor": x.ctacontable });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
             },

    editItem(item) {
      this.editedItem.IdConcepto = item.idConcepto;
      this.editedItem.Concepto = item.concepto;
      this.editedItem.Nombre = item.nombre;
      this.editedItem.CtaContable = item.ctaContable;
      this.editedItem.Tipo = item.tipo;
      this.editedItem.Cheque = item.cheque;
      this.editedIndex = 1;
      this.campo_bloqueado = true;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.limpiar();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    limpiar() {
      this.editedIndex = -1;
      this.editedItem.IdConcepto = "";
      this.editedItem.Concepto = "";
      this.editedItem.Nombre = "";
      this.editedItem.Tipo = "";
      this.editedItem.CtaContable = "";
      this.editedItem.Cheque = "";
      this.campo_bloqueado = false;
    },

    validar() {
      this.valida = 0;
      this.validamensaje = [];

      if (
        this.editedItem.Nombre.length < 3 ||
        this.editedItem.Nombre.length > 50
      ) {
        this.validamensaje.push(
          "El nombre debe tener mas de 3 Caracteres y Menos de 50 caracteres"
        );
      }

      if (this.editedItem.Concepto.length < 1) {
        this.validamensaje.push("El Codigo debe tener al menos 1 Caracteres");
      }

      if (this.editedItem.CtaContable.length < 1) {
        this.validamensaje.push(
          "La Cuenta Contable debe tener al menos 1 Caracteres"
        );
      }

      if (this.editedItem.Tipo.length < 1) {
        this.validamensaje.push(
          "El Tipo debe tener al menos 1 Caracteres"
        );
      }

      if (this.validamensaje.length) {
        this.valida = 1;
        this.Esperartermina();
      }
      return this.valida;
    },

    activardesacticarmostrar(accion, item) {
      this.addmodal = 1;
      this.addnombre = item.nombre;
      this.adid = item.idConcepto;
      if (accion == 1) {
        this.addaction = 1;
      } else if (accion == 2) {
        this.addaction = 2;
      } else {
        this.addmodal = 0;
      }
    },

    activar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put("api/BanConceptos/Activar/" + this.adid, {}, configuracion)
        .then(function (response) {
          me.addmodal = 0;
          me.addaction = 0;
          me.addnombre = "";
          me.adid = "";
          me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    desactivar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put("api/BanConceptos/DesActivar/" + this.adid, {}, configuracion)
        .then(function (response) {
          me.addmodal = 0;
          me.addaction = 0;
          me.addnombre = "";
          me.adid = "";
          me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    activardesactivarcerrar() {
      this.addmodal = 0;
    },

    validadcodigo() {
      let ma = this.editedItem;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/BanConceptos/BuscarCodigo/" + ma.Concepto, configuracion)
        .then(function (response) {
          alert("El Codigo ya Existe");
          ma.Concepto = "";
          console.log(response.data.length);
        })
        .catch(function (error) {
          console.log("No Encontrado");
        });
    },

    Guardar() {
      this.Esperarcargar();
      let ma = this;
      if (ma.validar()) {
        return;
      }

      if (this.editedIndex > -1) {
        //actualizar
        let me = this.editedItem;
        let ma = this;
        let header = { Authorization: "Bearer " + this.$store.state.token };
        let configuracion = { headers: header };
        axios
          .put(
            "api/BanConceptos/Actualizar",
            {
              idConcepto: me.IdConcepto,
              Concepto: me.Concepto,
              Nombre: me.Nombre,
              Tipo: me.Tipo,
              CtaContable: me.CtaContable,
              Cheque: me.Cheque,
            },
            configuracion
          )

          .then(function (response) {
            ma.close();
            ma.listar();
            ma.limpiar();
          })
          .finally(() => this.Esperartermina())
          .catch(function (error) {
            console.log(error);
            alert("Registo no Actualizado");
          });
      } else {
        let me = this.editedItem;
        let ma = this;
        let header = { Authorization: "Bearer " + this.$store.state.token };
        let configuracion = { headers: header };
        axios
          .post(
            "api/BanConceptos/Crear",
            {
              concepto: me.Concepto,
              nombre: me.Nombre,
              tipo: me.Tipo,
              ctaContable: me.CtaContable,
              cheque: me.Cheque
            },
            configuracion
          )
          .finally(() => this.Esperartermina())
          .then(function (response) {
            ma.close();
            ma.listar();
            ma.limpiar();
          })
          .catch(function (error) {
            console.log(error);
            alert("Registo no Actualizado");
          });
      }
    },
  },
};
</script>