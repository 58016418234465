<template>
  <v-data-table
    :headers="headers"
    :items="listadocumentoscxc"
    sort-by="CXCDocumentos"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Movimientos CXC</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>

        <v-col cols="6" sm="6" md="6">
          <v-radio-group v-model="Rangoseleccinado" row>
            <v-radio
              label="Regristros de Este mes"
              color="green"
              value="mes"
              @click="listar()"
            ></v-radio>
            <v-radio
              label="Regristros de Este Año"
              color="orange"
              value="ano"
              @click="listar()"
            ></v-radio>
            <v-radio
              label="Todos Los Registros"
              color="red"
              value="todos"
              @click="listar()"
            ></v-radio>
          </v-radio-group>
        </v-col>

        <v-dialog v-model="dialog" max-width="1000px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Nuevo
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col v-show="editedIndex" cols="12" sm="6" md="6">
                    <v-text-field
                      label="Codigo"
                      v-model="editedItem.Documento"
                      v-on:blur="validadcodigo()"
                      :disabled="campo_bloqueado"
                    >
                      ></v-text-field
                    >
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      v-model="editedItem.ConceptoCXC"
                      :items="listaConceptoCXC"
                      label="Concepto CXC"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.Referencia"
                      label="Referencia"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.Aplica"
                      label="Aplica"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="9" md="9">
                    <v-text-field
                      v-model="editedItem.Cliente"
                      label="Cliente"
                    ></v-text-field>
                  </v-col>

                   <v-flex xs2 sm2 md2 lg2 xl2>
            <v-btn @click="mostrarclintes()" small fab dark color="teal">
              <v-icon dark>list</v-icon>
            </v-btn>
          </v-flex>

                    <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.Proyecto"
                      label="Proyecto"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.Monto"
                      label="Monto"
                    ></v-text-field>
                  </v-col>
                
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.NFiscal"
                      label="Numero Fiscal"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.FAplica"
                      label="Numero Fiscal Aplica"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.Caja"
                      label="Caja"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.Serie"
                      label="Serie"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="12" sm="6" md="12" v-show="valida">
                    <v-text
                      class="red--text"
                      v-for="v in validamensaje"
                      :key="v"
                      v-text="v"
                    >
                    </v-text>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="dialogespera"
                :loading="dialogespera"
                @click="(dialogespera = true), Guardar()"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


            <v-dialog persistent v-model="verCliente" max-width="1000px">
          <v-card>
            <v-card-title>
              <span class="headline"> Seleccione Cliente</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm12 md12 lg12 xl12>
                    <v-text-field
                      append-icon="search"
                      class="text-xs-center"
                      v-model="texto"
                      label="Ingrese Cliente a buscar"
                      @keyup.enter="listarclientes()"
                    >
                    </v-text-field>
                    <template>
                      <v-data-table
                        :headers="cabeceraClientes"
                        :items="clientes"
                        class="elevation-1"
                      >
                        <template
                          v-slot:[`item.seleccionar`]="{ item }"
                          class="justify-center layout px-0"
                        >
                          <v-icon
                            small
                            class="mr-2"
                            @click="agregarcliente(item)"
                          >
                            add
                          </v-icon>
                        </template>
                        <template slot="no-data">
                          <h3>No hay Clientes para Mostrar.</h3>
                        </template>
                      </v-data-table>
                    </template>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="ocultarclintes()" color="primary" dark class="mb-2"
                >Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="addmodal" max-width="500px">
          <v-card>
            <v-card-title class="headline" v-if="addaction == 1">
              Desea Activar
            </v-card-title>
            <v-card-title class="headline" v-if="addaction == 2">
              Desea Desactivar
            </v-card-title>

            <v-card-text> Estas a Punto de: </v-card-text>
            <span v-if="addaction == 1"> Activar </span>
            <span v-if="addaction == 2"> Desactivar</span>
            el item {{ addnombre }}
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="activardesactivarcerrar"
                >Cancel</v-btn
              >
              <v-btn
                v-if="addaction == 1"
                color="blue darken-1"
                text
                @click="activar"
                >Activar</v-btn
              >
              <v-btn
                v-if="addaction == 2"
                color="blue darken-1"
                text
                @click="desactivar"
                >Desactivar</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> edit </v-icon>
    </template>

    <template v-slot:[`item.condicion`]="{ item }">
      <div v-if="item.condicion">
        <span class="blue--text">Activo</span>
      </div>
      <div v-else>
        <span class="red--text">Inactivo</span>
      </div>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="listar"> Reset </v-btn>
    </template>
  </v-data-table>
</template>


<script>
import axios from "axios";
export default {
  data: () => ({
    dialog: false,
    search: "",
    dialogDelete: false,
    listadocumentoscxc: [],
    listaConceptoCXC: [],
    listaclientes: [],
    listacajas: [],
    verCliente:0,
    clientes: [],
    texto: "",
  cabeceraClientes: [
      { text: "Seleccionar", value: "seleccionar", sortable: false },
      { text: "Idcliente", value: "idcliente" },
      { text: "Codigo", value: "cliente" },
      { text: "Nombre", value: "nombre" },
    ],
    headers: [
      { text: "Acciones", value: "actions", sortable: false },
      {
        text: "IdDocumento",
        align: "start",
        value: "idDocumento",
      },
      { text: "Codigo", value: "documento" },
      { text: "ConceptoCXC", value: "conceptoCXC" },
      { text: "Referencia", value: "referencia" },
      { text: "Aplica", value: "aplica" },
      { text: "Cliente", value: "cliente" },
      { text: "Fecha", value: "fecha" },
      { text: "Vence", value: "vence" },
      { text: "Monto", value: "monto" },
      { text: "Proyecto", value: "proyecto" },
      { text: "NFiscal", value: "nFiscal" },
      { text: "FAplica", value: "fAplica" },
      { text: "Caja", value: "caja" },
      { text: "Serie", value: "serie" },
    ],
    Rangoseleccinado: "mes",
    dialogespera: false,
    editedIndex: -1,
    editedItem: {
      IdDocumento: "",
      Documento: "",
      ConceptoCXC: "",
      Referencia: "",
      Aplica: "",
      Cliente: "",
      Fecha: "",
      Vence: "",
      Monto: "",
      Proyecto: "",
      NFiscal: "",
      FAplica: "",
      Caja: "",
      Serie: "",
    },
    valida: 0,
    validamensaje: [],
    addmodal: 0,
    addaction: 0,
    addnombre: "",
    adid: "",
    campo_bloqueado: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Documento CXC"
        : "Editando Documento CXC";
    },
  },

  created() {
    this.listar();
    this.listadoconceptoscxc();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    listar() {
      let url = "";
      console.log(this.Rangoseleccinado);

      if (this.Rangoseleccinado == "mes") {
        url = "api/CXCDocumentos/ListarMes";
      }

      if (this.Rangoseleccinado == "ano") {
        url = "api/CXCDocumentos/Listarano";
      }
      if (this.Rangoseleccinado == "todos") {
        url = "api/CXCDocumentos/Listar";
      }

      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get(url, configuracion)
        .then(function (response) {
          me.listadocumentoscxc = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    Esperarcargar() {
      this.dialogespera = true;
    },
    Esperartermina() {
      this.dialogespera = null;
    },



   mostrarclintes() {
      this.verCliente = 1;
    },
    ocultarclintes() {
      this.verCliente = 0;
    },
    
       listarclientes() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/Clientes/ListarIngreso/" + this.texto, configuracion)
        .then(function (response) {
          me.clientes = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

listadoconceptoscxc() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      var arrayinterno = [];
      axios
        .get("api/Conceptoscxc/select", configuracion)
        .then(function (response) {
          arrayinterno = response.data;
          arrayinterno.map(function (x) {
            me.listaConceptoCXC.push({ text: x.descripcion, value: x.conceptocxc });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

listadoclientes() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      var arrayinterno = [];
      axios
        .get("api/clientes/listar", configuracion)
        .then(function (response) {
          arrayinterno = response.data;
          arrayinterno.map(function (x) {
            me.listaclientes.push({ text: x.nombre, value: x.cliente });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem(item) {
      this.editedItem.IdDocumento = item.idDocumento;
      this.editedItem.Documento = item.documento;
      this.editedItem.ConceptoCXC = item.conceptoCXC;
      this.editedItem.Referencia = item.referencia;
      this.editedItem.Aplica = item.aplica;
      this.editedItem.Cliente = item.cliente;
      this.editedItem.Fecha = item.fecha;
      this.editedItem.Vence = item.vence;
      this.editedItem.Monto = item.monto;
      this.editedItem.Proyecto = item.proyecto;
      this.editedItem.NFiscal = item.nFiscal;
      this.editedItem.FAplica = item.fAplica;
      this.editedItem.Caja = item.caja;
      this.editedItem.Serie = item.serie;
      this.listadoclientes();
      this.editedIndex = 1;
      this.campo_bloqueado = true;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.limpiar();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    limpiar() {
      this.editedIndex = -1;
      this.editedItem.IdDocumento = "";
      this.editedItem.Documento = "";
      this.editedItem.ConceptoCXC = "";
      this.editedItem.Referencia = "";
      this.editedItem.Aplica = "";
      this.editedItem.Cliente = "";
      this.editedItem.Fecha = "";
      this.editedItem.Vence = "";
      this.editedItem.Monto = "";
      this.editedItem.NFiscal = "";
      this.editedItem.FAplica = "";
      this.editedItem.Caja = "";
      this.editedItem.Serie = "";
      this.campo_bloqueado = false;
      this.valida = "";
    },

    validar() {
      this.valida = 0;
      this.validamensaje = [];

      if (
        this.editedItem.Documento.length < 1 ||
        this.editedItem.Documento.length > 15
      ) {
        this.validamensaje.push(
          "El Documento debe tener mas de 1 Caracter y Menos de 15 caracteres"
        );
      }

      if (this.editedItem.ConceptoCXC.length > 3) {
        this.validamensaje.push(
          "El Concepto debe tener menos de 3  Caracteres"
        );
      }

      if (this.editedItem.Referencia.length < 3) {
        this.validamensaje.push(
          "La Referencia debe tener al menos 3 Caracteres"
        );
      }

      if (this.editedItem.Aplica.length < 3) {
        this.validamensaje.push("El Aplica debe tener al menos 3 Caracteres");
      }

      if (this.editedItem.Cliente.length < 3) {
        this.validamensaje.push("El Cliente debe tener al menos 3 Caracteres");
      }

      if (this.editedItem.Proyecto.length < 3) {
        this.validamensaje.push("El Proyecto debe tener al menos 3 Caracteres");
      }

      if (this.editedItem.NFiscal.length < 3) {
        this.validamensaje.push(
          "El Numero Fiscal debe tener al menos 3 Caracteres"
        );
      }

      if (this.editedItem.FAplica.length < 3) {
        this.validamensaje.push(
          "El Numero de Aplicacion Fiscal debe tener al menos 3 Caracteres"
        );
      }

      if (this.editedItem.Caja.length < 3) {
        this.validamensaje.push("La Caja debe tener al menos 3 Caracteres");
      }

      if (this.editedItem.Serie.length < 3) {
        this.validamensaje.push("LA Serie debe tener al menos 3 Caracteres");
      }

      if (this.validamensaje.length) {
        this.valida = 1;
        this.Esperartermina();
      }
      return this.valida;
    },

    activardesacticarmostrar(accion, item) {
      this.addmodal = 1;
      this.addnombre = item.nombre;
      this.adid = item.idconceptocxc;
      if (accion == 1) {
        this.addaction = 1;
      } else if (accion == 2) {
        this.addaction = 2;
      } else {
        this.addmodal = 0;
      }
    },

    activar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put("api/CXCDocumentos/Activar/" + this.adid, {}, configuracion)
        .then(function (response) {
          me.addmodal = 0;
          me.addaction = 0;
          me.addnombre = "";
          me.adid = "";
          me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    desactivar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put("api/CXCDocumentos/DesActivar/" + this.adid, {}, configuracion)
        .then(function (response) {
          me.addmodal = 0;
          me.addaction = 0;
          me.addnombre = "";
          me.adid = "";
          me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    activardesactivarcerrar() {
      this.addmodal = 0;
    },

    validadcodigo() {
      let ma = this.editedItem;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/Conceptoscxc/BuscarCodigo/" + ma.Concepto, configuracion)
        .then(function (response) {
          alert("El Codigo ya Existe");
          ma.Concepto = "";
          console.log(response.data.length);
        })
        .catch(function (error) {
          console.log("No Encontrado");
        });
    },

    Guardar() {
      this.Esperarcargar();
      let ma = this;
      if (ma.validar()) {
        return;
      }

      if (this.editedIndex > -1) {
        //actualizar
        let me = this.editedItem;
        let ma = this;
        let header = { Authorization: "Bearer " + this.$store.state.token };
        let configuracion = { headers: header };
        axios
          .put(
            "api/CXCDocumentos/Actualizar",
            {
              idDocumento: me.IdDocumento,
              documento: me.Documento,
              conceptoCXC: me.ConceptoCXC,
              referencia: me.Referencia,
              aplica: me.Aplica,
              cliente: me.Cliente,
              fecha: me.Fecha,
              vence: me.Vence,
              monto: me.Monto,
              nFiscal: me.NFiscal,
              fAplica: me.FAplica,
              caja: me.Caja,
              serie: me.Serie,
            },
            configuracion
          )

          .then(function (response) {
            ma.close();
            ma.listar();
            ma.limpiar();
          })
          .finally(() => this.Esperartermina())
          .catch(function (error) {
            console.log(error);
            alert("Registo no Actualizado");
          });
      } else {
        let me = this.editedItem;
        let ma = this;
        let header = { Authorization: "Bearer " + this.$store.state.token };
        let configuracion = { headers: header };
        axios
          .post(
            "api/CXCDocumentos/Crear",
            {
              documento: me.Documento,
              conceptocxc: me.ConceptoCXC,
              referencia: me.Referencia,
              aplica: me.Aplica,
              cliente: me.Cliente,
              monto: me.Monto,
              proyecto: me.Proyecto,
              nFiscal: me.NFiscal,
              fAplica: me.FAplica,
              caja: me.Caja,
              serie: me.Serie,
            },
            configuracion
          )
          .finally(() => this.Esperartermina())
          .then(function (response) {
            ma.close();
            ma.listar();
            ma.limpiar();
          })
          .catch(function (error) {
            console.log(error);
            alert("Registo no Actualizado");
          });
      }
    },
  },
};
</script>