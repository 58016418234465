<template>
  <v-data-table
    :headers="headers"
    :items="equipomodelos"
    sort-by="idequipomodelo"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Equipo Modelo</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="1000px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Nuevo
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.Nombre"
                      label="Nombre"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      v-model="editedItem.idequipomarca"
                      :items="marcas"
                      label="Marca"
                    >
                    </v-select>
                    <v-card>
                               <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Ingresar Productos Minimo
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>

                      
                      <v-card-text>
                        <v-row>
                          <v-flex xs12 sm6 md6 lg6 xl6>
                            <v-text-field
                              @keyup.enter="buscarcodigo()"
                              id="codigo"
                              v-model="codigo"
                              label="Código"
                            >
                            </v-text-field>
                          </v-flex>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-flex xs12 sm5 md5 lg5 xl5>
                            <v-text-field
                              class="rigth-input"
                              justify-right
                              v-model="cantidadminimotmp"
                              type="number"
                              label="Cantidad Minima"
                              min="1" pattern="^[0-9]+"
                            >
                            </v-text-field>
                          </v-flex>
                        </v-row>

                        <v-row>
                          <v-flex xs12 sm12 md12 lg12 x12>
                            <v-select
                              v-model="idalmacen"
                              :items="Almacenes"
                              label="Almacen"
                            >
                            </v-select>
                          </v-flex>
                        </v-row>

                        <v-btn
                          @click="buscarcodigo()"
                          block
                          elevation="4"
                          color="accent"
                          >Agregar al Detalle</v-btn
                        >
                        </v-card-text>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>

                        <v-flex xs12 sm12 md12 lg12 xl12 v-if="errorArticulo">
                          <div class="red--text" v-text="errorArticulo"></div>
                        </v-flex>
                        <v-card-title>Detalle Minimos Definidos</v-card-title>
                        <v-data-table
                          :headers="cabeceraDetalles"
                          :items="detalles"
                          class="elevation-1"
                        >
                          <template
                            v-slot:[`item.borrar`]="{ item }"
                            class="justify-center layout px-0"
                          >
                            <v-icon
                              small
                              class="mr-2"
                              @click="eliminardetalle(detalles, item)"
                            >
                              delete
                            </v-icon>
                          </template>
                          <template v-slot:[`item.cantidad`]="{ item }">
                            <v-text-field
                              min="1"
                              v-model="item.cantidad"
                              type="number"
                            >
                            </v-text-field>
                          </template>
                          <template slot="no-data">
                            <h3>No hay artículos agregados al detalle.</h3>
                          </template>
                        </v-data-table>
                      
                      
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="12" v-show="valida">
                    <v-text
                      class="red--text"
                      v-for="v in validamensaje"
                      :key="v"
                      v-text="v"
                    >
                    </v-text>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="Guardar">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="addmodal" max-width="500px">
          <v-card>
            <v-card-title class="headline" v-if="addaction == 1">
              Desea Activar
            </v-card-title>
            <v-card-title class="headline" v-if="addaction == 2">
              Desea Desactivar
            </v-card-title>

            <v-card-text> Estas a Punto de: </v-card-text>
            <span v-if="addaction == 1"> Activar </span>
            <span v-if="addaction == 2"> Desactivar</span>
            el item {{ addnombre }}
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="activardesactivarcerrar"
                >Cancel</v-btn
              >
              <v-btn
                v-if="addaction == 1"
                color="blue darken-1"
                text
                @click="activar"
                >Activar</v-btn
              >
              <v-btn
                v-if="addaction == 2"
                color="blue darken-1"
                text
                @click="desactivar"
                >Desactivar</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> edit </v-icon>
      <template v-if="item.condicion">
        <v-icon small @click="activardesacticarmostrar(2, item)">
          block
        </v-icon>
      </template>
      <template v-else>
        <v-icon small @click="activardesacticarmostrar(1, item)">
          check
        </v-icon>
      </template>
    </template>

    <template v-slot:[`item.condicion`]="{ item }">
      <div v-if="item.condicion">
        <span class="blue--text">Activo</span>
      </div>
      <div v-else>
        <span class="red--text">Inactivo</span>
      </div>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="listar"> Reset </v-btn>
    </template>
  </v-data-table>
</template>


<script>
import axios from "axios";
export default {
  data: () => ({
    dialog: false,
    search: "",
    codigo: "",
    errorArticulo: null,
    dialogDelete: false,
    cabeceraDetalles: [
      { text: "Borrar", value: "borrar", sortable: false },
      { text: "Codigo", value: "producto", sortable: false },
      { text: "Nombre", value: "nproducto", sortable: false },
      { text: "Almacen", value: "nalmacen", sortable: false },
      { text: "Cantidad", value: "cantidad", sortable: false },
    ],
    cantidadminimotmp: "",
    idalmacen: "",
    Almacenes: [],
    detalles: [],

    headers: [
      { text: "Acciones", value: "actions", sortable: false },
      {
        text: "Id Modelo",
        align: "start",
        value: "idequipomodelo",
      },
      { text: "Nombre", value: "nombre" },
      { text: "Marca", value: "nmarca" },
      { text: "Condicion", value: "condicion" },
    ],
    equipomodelos: [],
    editedIndex: -1,
    editedItem: {
      idequipomodelo: "",
      Nombre: "",
      idequipomarca: "",
    },
    valida: 0,
    validamensaje: [],
    addmodal: 0,
    addaction: 0,
    addnombre: "",
    adid: "",
    campo_bloqueado: false,
    marcas: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Modelo Equipo"
        : "Editando Modelo Equipo";
    },
  },

  created() {
    this.listar();
    this.Selectmarcas();
    this.SelectAlmacenes();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    listar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/EquiposModelos/Listar", configuracion)
        .then(function (response) {
          me.equipomodelos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    buscarcodigo() {
      let me = this;
      this.errorArticulo = null;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/Productos/BuscarCodigo/" + this.codigo, configuracion)
        .then(function (response) {
          // console.log(response);

          me.agregardetalle(response.data);
        })
        .catch(function (error) {
          console.log(error);
          me.errorArticulo = "Articulo No encontrado";
        });
      this.codigo = "";
    },

    agregardetalle(data = []) {
      if (
        data["idproducto"].length <= 0 ||
        this.cantidadminimotmp <= 0 ||
        this.idalmacen.length <= 0
      ) {
        this.errorArticulo = "Ingrese un Valor Valido";
        return;
      }
      this.errorArticulo = null;
      if (this.encuentra(data["idproducto"])) {
        this.errorArticulo = "Ya se encuentra agregado";
      } else {
        this.detalles.push({
          idproducto: data["idproducto"],
          producto: data["producto"],
          nproducto: data["nombre"],
          disponible: data["stock"],
          idalmacen: this.idalmacen,
          nalmacen: this.Almacenes[this.idalmacen - 1].text,
          cantidad: this.cantidadminimotmp,
        });
        this.cantidadminimotmp = "";
      }
    },

    eliminardetalle(arr, item) {
      var i = arr.indexOf(item);
      if (i != -1) {
        arr.splice(i, 1);
      }
    },

    encuentra(id) {
      var sw = 0;
      for (var i = 0; i < this.detalles.length; i++) {
        if (this.detalles[i].idproducto == id) {
          for (var j = 0; j < this.detalles.length; j++) {
            if (this.detalles[i].idalmacen == this.idalmacen) {
              sw = 1;
            }
          }
        }
      }
      return sw;
    },

    editItem(item) {
      this.editedItem.idequipomodelo = item.idequipomodelo;
      this.editedItem.Nombre = item.nombre;
      this.editedItem.idequipomarca = item.idequipomarca;
      this.editedIndex = 1;
      this.campo_bloqueado = true;
      this.dialog = true;
      this.listardetalle(item.idequipomodelo);
    },

       listardetalle(id) {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/Productos/ProductosMinimosModelo/" + id, configuracion)
        .then(function (response) {
          me.detalles = response.data;
        })
        .catch(function (error) {
          console.log(error);
          alert("No podra Actualizar la Cotizacion");
        });
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.limpiar();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    limpiar() {
      this.editedIndex = -1;
      this.editedItem.Nombre = "";
      this.editedItem.idequipomodelo = "";
      this.campo_bloqueado = false;
      this.editedItem.idequipomarca = "";
    },

    Selectmarcas() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      var marcasaray = [];
      axios
        .get("api/EquiposMarcas/Select", configuracion)
        .then(function (response) {
          marcasaray = response.data;
          marcasaray.map(function (x) {
            me.marcas.push({ text: x.nombre, value: x.idequipomarca });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    validar() {
      this.valida = 0;
      this.validamensaje = [];

      if (
        this.editedItem.Nombre.length < 3 ||
        this.editedItem.Nombre.length > 50
      ) {
        this.validamensaje.push(
          "El nombre debe tener mas de 3 Caracteres y Menos de 50 caracteres"
        );
      }

      if (
        !this.editedItem.idequipomarca ||
        this.editedItem.idequipomarca === ""
      ) {
        this.validamensaje.push("*Selecione una Marca de Equipo.");
      }

      if (this.validamensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },

    activardesacticarmostrar(accion, item) {
      this.addmodal = 1;
      this.addnombre = item.nombre;
      this.adid = item.idequipomodelo;
      if (accion == 1) {
        this.addaction = 1;
      } else if (accion == 2) {
        this.addaction = 2;
      } else {
        this.addmodal = 0;
      }
    },

    activar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put("api/EquiposModelos/Activar/" + this.adid, {}, configuracion)
        .then(function (response) {
          me.addmodal = 0;
          me.addaction = 0;
          me.addnombre = "";
          me.adid = "";
          me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    SelectAlmacenes() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      var almacenesaray = [];
      axios
        .get("api/Almacenes/Select", configuracion)
        .then(function (response) {
          almacenesaray = response.data;
          almacenesaray.map(function (x) {
            me.Almacenes.push({ text: x.nombre, value: x.idalmacen });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    desactivar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put("api/EquiposModelos/DesActivar/" + this.adid, {}, configuracion)
        .then(function (response) {
          me.addmodal = 0;
          me.addaction = 0;
          me.addnombre = "";
          me.adid = "";
          me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    activardesactivarcerrar() {
      this.addmodal = 0;
    },

    Guardar() {
      let ma = this;
      if (ma.validar()) {
        return;
      }

      if (this.editedIndex > -1) {
        //actualizar
        let ma = this;
        let me = this.editedItem;
        let header = { Authorization: "Bearer " + this.$store.state.token };
        let configuracion = { headers: header };
        axios
          .put(
            "api/EquiposModelos/Actualizar",
            {
              idequipomodelo: me.idequipomodelo,
              idequipomarca: me.idequipomarca,
              nombre: me.Nombre,
              productosminimosdetalle:ma.detalles
            },
            configuracion
          )
          .then(function (response) {
            ma.close();
            ma.listar();
            ma.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        let header = { Authorization: "Bearer " + this.$store.state.token };
        let configuracion = { headers: header };
        let me = this.editedItem;
        let ma = this;
        axios
          .post(
            "api/EquiposModelos/Crear",
            { Nombre: me.Nombre, idequipomarca: me.idequipomarca,productosminimosdetalle:ma.detalles },
            configuracion
          )
          .then(function (response) {
            ma.close();
            ma.listar();
            ma.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
};
</script>