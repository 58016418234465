<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-container>
      <v-row>
        <div class="col s12 m4">
          <v-list-item class="blue lighten-5 elevation-4">
            <v-list-item-avatar color="orange">
              <v-icon dark>calendar_today</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Venta Dia</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content align="right">
              <v-list-item-title class="indigo--text">{{
                new Intl.NumberFormat("en-US").format(this.TDia.toFixed(2))
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-row>
      <v-row>
        <div class="col s12 m4">
          <v-list-item class="blue lighten-5 elevation-4" @click="step = 1">
            <v-list-item-avatar color="blue">
              <v-icon dark>attach_money</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Venta Anual</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content align="right">
              <v-list-item-title class="indigo--text">{{
                new Intl.NumberFormat("en-US").format(this.TAnual.toFixed(2))
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <div class="col s12 m4">
          <v-list-item class="blue lighten-5 elevation-4" @click="(step = 2), generargraficar()">
            <v-list-item-avatar color="red">
              <v-icon dark>attach_money</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Ultimo Mes</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content align="right">
              <v-list-item-title class="indigo--text">{{
                new Intl.NumberFormat("en-US").format(this.TMes.toFixed(2))
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-row>
      <v-row>
        <div class="col s12 m4">
          <v-list-item  class="blue lighten-5 elevation-4" @click="(step = 3), generargraficar()">
            <v-list-item-avatar color="purple">
              <v-icon dark>category</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Venta Por Categoria</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content align="right"> </v-list-item-content>
          </v-list-item>
        </div>

        <div class="col s12 m4">
          <v-list-item class="blue lighten-5 elevation-4" @click="(step = 4), generargraficar()">
            <v-list-item-avatar color="green">
              <v-icon dark>person</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Ventas Por Coolaborador</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-row>

      <div class="row">
        <div class="col">
          <div class="card">
            <v-window v-model="step" reverse>
              <v-window-item :value="1">
                <div class="card-header">
                  <h1 class="headline mb-2 grey--text">
                    Detalle del Venta del Año
                  </h1>
                </div>
                <v-flex xs12 sm12 md12 lg12 xl12>
                  <v-row>
                    <div class="col-md-8">
                      <canvas id="myChart"></canvas>
                    </div>
                    <div class="col-md-4">
                      <div class="alert-wrap">
                        <div class="alert alert-success">
                          <v-data-table
                            :headers="DetallesAno"
                            :items="anoValores"
                            :page.sync="page"
                            :items-per-page="itemsPerPage"
                            hide-default-footer
                            class="elevation-1"
                            @page-count="pageCount = $event"
                          >
                            <template
                              v-slot:[`item.valor`]="{ item }"
                              class="justify-center layout px-0"
                            >
                              {{
                                new Intl.NumberFormat("en-US").format(
                                  item.valor.toFixed(2)
                                )
                              }}
                            </template>

                            <template v-slot:[`item.etiqueta`]="{ item }">
                              {{ obtenerNombreMes(parseInt(item.etiqueta)) }}
                            </template>

                            <template slot="no-data">
                              <h3>No hay artículos para Mostrar.</h3>
                            </template>
                          </v-data-table>
                          <div class="text-center">
                            <v-pagination
                              class="elevation-0"
                              v-model="page"
                              :length="pageCount"
                            ></v-pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-row>
                </v-flex>
              </v-window-item>

              <v-window-item :value="2">
                <div class="card-header">
                  <h1 class="headline mb-2 grey--text">
                    Detalle del Venta del Mes
                  </h1>
                </div>
                <v-flex xs12 sm12 md12 lg12 xl12>
                  <v-row>
                    <div class="col-md-8">
                      <canvas id="myChart2"></canvas>
                    </div>
                    <div class="col-md-4">
                      <div class="alert-wrap">
                        <div class="alert alert-success">
                          <v-data-table
                            :headers="CabeceraDia"
                            :items="diasValores"
                            :page.sync="pagemes"
                            :items-per-page="itemsPerPage"
                            hide-default-footer
                            class="elevation-1"
                            @page-count="pageCountmes = $event"
                          >
                            <template
                              v-slot:[`item.valor`]="{ item }"
                              class="justify-center layout px-0"
                            >
                              {{
                                new Intl.NumberFormat("en-US").format(
                                  item.valor.toFixed(2)
                                )
                              }}
                            </template>

                            <template slot="no-data">
                              <h3>No hay artículos para Mostrar.</h3>
                            </template>
                          </v-data-table>
                          <div class="text-center">
                            <v-pagination
                              class="elevation-0"
                              v-model="pagemes"
                              :length="pageCountmes"
                            ></v-pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-row>
                </v-flex>
              </v-window-item>

              <v-window-item :value="3">
                <div class="card-header">
                  <h1 class="headline mb-2 grey--text">
                    Detalle Por Categoria
                  </h1>
                </div>
                <v-flex xs12 sm12 md12 lg12 xl12>
                  <v-row>
                    <div class="col-md-8">
                      <canvas id="myChart4"></canvas>
                    </div>
                    <div class="col-md-4">
                      <div class="alert-wrap">
                        <div class="alert alert-success">
                          <v-data-table
                            :headers="DetallesarrayCat"
                            :items="categoriasValores"
                            :page.sync="pagecat"
                            :items-per-page="itemsPerPage"
                            hide-default-footer
                            class="elevation-1"
                            @page-count="pageCountcat = $event"
                          >
                            <template
                              v-slot:[`item.valor`]="{ item }"
                              class="justify-center layout px-0"
                            >
                              {{
                                new Intl.NumberFormat("en-US").format(
                                  item.valor.toFixed(2)
                                )
                              }}
                            </template>
                            <template slot="no-data">
                              <h3>No hay artículos para Mostrar.</h3>
                            </template>
                          </v-data-table>
                          <div class="text-center">
                            <v-pagination
                              class="elevation-0"
                              v-model="pagecat"
                              :length="pageCountcat"
                            ></v-pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-row>
                </v-flex>
              </v-window-item>

              <v-window-item :value="4">
                <div class="card-header">
                  <h1 class="headline mb-2 grey--text">
                    Detalle Por Coolaborador
                  </h1>
                </div>
                <v-flex xs12 sm12 md12 lg12 xl12>
                  <v-row>
                    <div class="col-md-8">
                      <canvas id="myChart3"></canvas>
                    </div>
                    <div class="col-md-4">
                      <div class="alert-wrap">
                        <div class="alert alert-success">
                          <v-data-table
                            :headers="Detallesarrayven"
                            :items="vendedorValores"
                            :page.sync="pageven"
                            :items-per-page="itemsPerPage"
                            hide-default-footer
                            class="elevation-1"
                            @page-count="pageCountven = $event"
                          >
                            <template
                              v-slot:[`item.valor`]="{ item }"
                              class="justify-center layout px-0"
                            >
                              {{
                                new Intl.NumberFormat("en-US").format(
                                  item.valor.toFixed(2)
                                )
                              }}
                            </template>

                            <template slot="no-data">
                              <h3>No hay artículos para Mostrar.</h3>
                            </template>
                          </v-data-table>
                          <div class="text-center">
                            <v-pagination
                              class="elevation-0"
                              v-model="pageven"
                              :length="pageCountven"
                            ></v-pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-row>
                </v-flex>
              </v-window-item>
            </v-window>

          </div>
          
        </div>
        
      </div>
      <v-card>
        <h1 class="headline mb-2 grey--text">Detalle de Ventas</h1>
            <v-expansion-panels accordion>
                        <v-expansion-panel key="1">
                          <v-expansion-panel-header
                            class="white--text"
                            color=" blue lighten-1"
                            >              <template v-slot:actions>
                                    
                                </template>
                                <span class="header">Top 10 Clientes {{new Intl.NumberFormat("en-US").format(
                                                          TTopClientes.toFixed(2)
                                                        )}}
                          </span></v-expansion-panel-header
                                                  >
                          <v-expansion-panel-content>
                                 <template>
                      <v-data-table
                      
                        :headers="DetallesClientes"
                        :items="Valoresclientes"
                        class="elevation-1"
                      >
                   
                        <template slot="no-data">
                          <h3>No hay artículos para Mostrar.</h3>
                        </template>
                      </v-data-table>

                    </template>

                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>

                      <v-expansion-panels accordion>
                        <v-expansion-panel key="1">
                          <v-expansion-panel-header
                            class="white--text"
                            color="red lighten-1"
                            >Top Productos</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                          
                           <v-data-table
                      
                        :headers="DetallesTopProductos"
                        :items="ValoresProductos"
                        class="elevation-1"
                      >
                   
                        <template slot="no-data">
                          <h3>No hay artículos para Mostrar.</h3>
                        </template>
                      </v-data-table>

                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
          </v-card>
<!--
      <v-layout row class="ma-12">
        <v-app style="background-color: grey lighten-1">
          <v-row>
              <v-container>
                <v-row>
                  <v-col cols="12" md12
                    >
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-card>
                     
                      

                      <v-list>
                        <v-list-item>
                          <v-list-item-avatar color="blue">
                            <v-icon dark>attach_money</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-expansion-panels accordion>
                              <v-expansion-panel key="1">
                                <v-expansion-panel-header
                                  class="white--text"
                                  color="red"
                                  >Top Clientes</v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                   <v-data-table
                      
                        :headers="DetallesTopProductos"
                        :items="ValoresProductos"
                        class="elevation-1"
                      >
                   
                        <template slot="no-data">
                          <h3>No hay artículos para Mostrar.</h3>
                        </template>
                      </v-data-table>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>
                        <v-list-item @click="(step = 2), generargraficar()">
                          <v-list-item-avatar color="red">
                            <v-icon dark>attach_money</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Venta Mensual</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-content align="right">
                            <v-list-item-title class="indigo--text">{{
                              new Intl.NumberFormat("en-US").format(
                                this.TMes.toFixed(2)
                              )
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>
                        <v-list-item @click="step = 3">
                          <v-list-item-avatar color="orange">
                            <v-icon dark>calendar_today</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Venta Dia</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-content align="right">
                            <v-list-item-title class="indigo--text">{{
                              new Intl.NumberFormat("en-US").format(
                                this.TDia.toFixed(2)
                              )
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item @click="step = 4">
                          <v-list-item-avatar color="green">
                            <v-icon dark>person</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              >Ventas Por Coolaborador</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>
                        <v-list-item @click="step = 1">
                          <v-list-item-avatar color="blue">
                            <v-icon dark>category</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              >Ventas Por Categoria</v-list-item-title
                            >
                          </v-list-item-content>
                          <v-list-item-content align="right">
                            <v-list-item-title
                              class="indigo--text"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
          
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
           

            <v-col cols="12" md="4">
              
              <v-container>
                <v-row>
                  <v-col cols="12" md12>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-img src="img1.png"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>AAe IdeaPro</v-list-item-title>
                        <v-list-item-subtitle
                          >aaeideapro@gmail.com</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon>fas fa-sign-out-alt</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" md12>
                    <v-list-item>
                      <v-list-item-avatar color="grey">
                        <v-icon color="white">fas fa-dollar-sign</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Heisenberg wallet</v-list-item-subtitle
                        >
                        <v-list-item-title>$ 15,423</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="indigo" large="">fab fa-cc-visa</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md12>
                    <v-list-item>
                      <v-list-item-avatar color="grey">
                        <v-icon color="white">fas fa-dollar-sign</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Grat Wall wallet</v-list-item-subtitle
                        >
                        <v-list-item-title>$ 6,211</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="black" large=""
                          >fab fa-cc-mastercard</v-icon
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md12>
                    <v-list-item>
                      <v-list-item-avatar color="grey">
                        <v-icon color="white">fas fa-dollar-sign</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Stone River wallet</v-list-item-subtitle
                        >
                        <v-list-item-title>$ 15,423</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="green" large="">fab fa-cc-paypal</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" md12>
                    <v-list-item>
                      <v-list-item-avatar color="indigo">
                        <v-icon color="white">fas fa-plus</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Add credit card</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md12>
                    <v-card color="#952175" dark>
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                          <v-card-title class="headline">
                            Upgrade your plan to GOLD
                          </v-card-title>

                          <v-card-subtitle
                            >With Gold account plan you get access to premium
                            features</v-card-subtitle
                          >
                        </div>

                        <v-avatar class="ma-3" size="100" tile>
                          <v-img src="gold.jpg"></v-img>
                        </v-avatar>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            
          </v-row>
        </v-app>
      </v-layout>
      !-->
    </v-container>
  </v-app>
</template>




<script>
import axios from "axios";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);
export default {
  data: () => ({
    totalMesestabla: [],
    diasValores: [],
    mesesValores: [],
    nombreMeses: [],
    totalMeses: [],
    anoValores: [],
    nombreano: [],
    totalano: [],
    step: 1,
    length: 3,
    onboarding: 0,
    meses: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    nobredia: [],
    vendedorValores: null,
    nombrevendedor: [],
    totalvendedor: [],
    ValoresDia: [],
    totaldia: [],
    Valoresano: [],
    Valoresmes: [],
    categoriasValores: [],
    arrayCat: [],
    DetallesarrayCat: [
      { text: "Categoria", value: "etiqueta", sortable: false },
      { text: "Monto", value: "valor" },
    ],
    Detallesarrayven: [
      { text: "Vendedor", value: "etiqueta", sortable: false },
      { text: "Monto", value: "valor" },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    pagemes: 1,
    pageCountmes: 0,
    pagecat: 1,
    pageCountcat: 0,
    pageven: 1,
    pageCountven: 0,
    totalcategoria: [],
    nombrecategoria: [],
    Valoresclientes: [],
    periodos: ["Ventas Ultimos 15 dias", "Ventas Anuales", "Ventas x Vendedor"],

    TAnual: 0,
    TTopClientes: 0,
    TTopProductosV: 0,
    TTopProductosC: 0,
    Fecha: new Date(),
    TMes: 0,
    TDia: 0,
    TCategoria: 0,
    TMinimoCategoria: 0,
    pagination: {},
    tdias: 0,
    DetallesDia: [
      { text: "Caja", value: "caja", sortable: false },
      { text: "Codigo", value: "tipo", sortable: false },
      { text: "Monto", value: "monto", sortable: false },
      { text: "%", value: "Porcentaje", sortable: false },
    ],
    CabeceraDia: [
      { text: "Dia", value: "etiqueta", sortable: false },
      { text: "Monto", value: "valor", sortable: false },
    ],
    DetallesAno: [
      { text: "mes", value: "etiqueta", sortable: false },
      { text: "monto", value: "valor", sortable: false },
    ],
    DetallesClientes: [
      { text: "Cliente", value: "ncliente", sortable: false },
      { text: "Monto", value: "monto" },
      { text: "%", value: "Porcentaje" },
    ],
    ValoresProductos: [],
    DetallesTopProductos: [
      { text: "Nombre", value: "nproducto", sortable: false },
      { text: "Uni_Vendidas", value: "cantidad" },
      { text: "Monto", value: "monto" },
    ],
    UtilidadProductos: [],
    DetalleUtilidadProductos: [
      { text: "Nombre", value: "nproducto", sortable: false },
      { text: "Monto Facturado", value: "monto" },
      { text: "%", value: "porcentaje" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Modelo Equipo"
        : "Editando Modelo Equipo";
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },

  created() {},

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.VentasAnuales();
    this.VentasMensual();
    this.VentasVendedor();
    this.Ventascategoria();
  },

  methods: {
    VentasMensual() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/VST_VentasTotales/Ventasdias", configuracion)
        .then(function (response) {
          console.log(response);

          me.diasValores = response.data;
          me.loadVentasMensual();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    Ventascategoria() {
      let me = this;
      me.arrayCat = [];
      me.TCategoria = 0;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/VST_VentasxProductos/VentasCostosxCategoria", configuracion)
        .then(function (response) {
          //console.log(response);
          me.arrayCat = response.data;
          me.arrayCat.map(function (x) {
            me.TCategoria = me.TCategoria + x.valor;
            //alert(me.TCategoria)
            me.categoriasValores.push({ etiqueta: x.etiqueta, valor: x.valor });
          });
          me.loadcategorias();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    VentasVendedor() {
      this.vendedorValores = [];
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/VST_VentasTotales/Ventasvendedor", configuracion)
        .then(function (response) {
          //console.log(response);
          me.vendedorValores = response.data;
          me.loadVentasVendedor();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
     Esperarcargar() {
      this.dialog = true;
    },
    Esperartermina() {
      this.dialog = null;
    },

    obtenerNombreMes(numero) {
      if (0 < numero && numero <= 12) {
        return this.meses[numero - 1];
      } else {
        return null;
      }
    },

    VentasAnuales() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/VST_VentasTotales/VentasMes12", configuracion)
        .then(function (response) {
          //console.log(response);
          me.anoValores = response.data;

          me.loadVentasAnuales();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    Ventasfechaclientes() {
      let me = this;
      me.TTopClientes = 0;
      me.Valoresclientes = [];
      let today = new Date();
      var year = me.Fecha.getFullYear();
      var dateStringI = year + "-01-01";
      var dateStringF = year + "-12-31";
      var Arraymes = [];
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get(
          "api/VST_VentasTotales/VentasxCliente/" +
            dateStringI +
            "/" +
            dateStringF,
          configuracion
        )
        .then(function (response) {
          //console.log(response);
          Arraymes = response.data;
          Arraymes.map(function (x) {
            me.TTopClientes = me.TTopClientes + x.valor;
            me.Valoresclientes.push({
              ncliente: x.etiqueta,
              monto: x.valor,
              Porcentaje: ((x.valor / me.TAnual) * 100).toFixed(2),
            });
          });
          //me.loadVentasVendedor();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    loadVentasVendedor() {
      let me = this;
      let mesn = "";
      const d = new Date();
      me.vendedorValores.map(function (x) {
        me.nombrevendedor.push(x.etiqueta);
        me.totalvendedor.push(x.valor);
      });
    },

    resolveAfter2Seconds() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve("resolved");
        }, 2000);
      });
    },

    loadVentasMensual() {
      let me = this;
      //alert(me.mesesValores)
      me.diasValores.map(function (x) {
        //alert(me.Fecha.getDate())
        if (me.Fecha.getDate() == parseInt(x.etiqueta)) {
          me.TDia = x.valor;
        }
        me.nobredia.push(x.etiqueta);
        me.ValoresDia.push(x.valor);
      });
    },

    graficomes() {
      let me = this;
      var ctx2 = document.getElementById("myChart2");
      var myChart2 = new Chart(ctx2, {
        type: "line",
        data: {
          labels: me.nobredia,
          datasets: [
            {
              label: "Ventas en los últimos 15 Dias",
              data: me.ValoresDia,
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 206, 86)",
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 206, 86)",
                "rgb(75, 192, 192)",
                "rgb(153, 102, 255)",
                "rgb(255, 159, 64)",
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 206, 86)",
                "rgb(75, 192, 192)",
                "rgb(153, 102, 255)",
                "rgb(255, 159, 64)",
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 206, 86)",
              ],
              borderColor: [
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
              ],
              borderWidth: 2,
              borderRadius: 20,
              borderSkipped: false,
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },

    loadcategorias() {
      let me = this;
      me.TMinimoCategoria = 0;
      me.categoriasValores.map(function (x) {
        //alert(me.Fecha.getDate())
        if (((x.valor / me.TCategoria) * 100).toFixed(2) > 3) {
          me.nombrecategoria.push(
            x.etiqueta + ((x.valor / me.TCategoria) * 100).toFixed(2) + "%"
          );
          me.totalcategoria.push(x.valor / me.TCategoria);
        } else {
          me.TMinimoCategoria = x.valor / me.TCategoria + me.TMinimoCategoria;
        }
      });
      me.nombrecategoria.push(
        "Otros" + (me.TMinimoCategoria * 100).toFixed(2) + "%"
      );
      me.totalcategoria.push(me.TMinimoCategoria);
    },
    VentasProductosMeses12() {
      let me = this;
      me.TTopProductosV = 0;
      me.TopProductosC = 0;
      me.ValoresProductos = [];
      var Arraymes = [];
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/VST_VentasxProductos/VentasProductosMeses12/", configuracion)
        .then(function (response) {
          //console.log(response);
          Arraymes = response.data;
          Arraymes.map(function (x) {
            me.TTopProductosV = me.TTopProductosV + x.valor;
            me.TopProductosC = me.TopProductosC + x.valor2;
            me.ValoresProductos.push({
              producto: x.producto,
              nproducto: x.etiqueta,
              cantidad: x.valor,
              monto: x.valor2,
            });
          });
          //me.loadVentasVendedor();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    generargraficar() {
      this.LoadGrafica();
    },
    async LoadGrafica() {
      console.log("calling");
      const result = await this.resolveAfter2Seconds();
      console.log(result);
      if (this.step == 2) {
        this.graficomes();
      }
      if (this.step == 3) {
        this.graficocat();
      }
      if (this.step == 4) {
        this.graficovend();
      }
    },

    graficocat() {
      let me = this;
      var ctx4 = document.getElementById("myChart4");
      var myChart4 = new Chart(ctx4, {
        type: "bar",
        data: {
          labels: me.nombrecategoria,
          datasets: [
            {
              label: "Ventas Por Categorias",
              data: me.totalcategoria,
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 206, 86)",
                "rgb(39, 174, 96)",
                "rgb(230, 126, 34)",
                "rgb(218, 247, 166)",
                "rgb(125, 60, 152)",
                "rgb(217, 136, 128)",
                "rgb(245, 183, 177)",
                "rgb(84, 153, 199)",
                "rgb(118, 215, 196)",
                "rgb(255, 206, 86)",
                "rgb(75, 192, 192)",
                "rgb(153, 102, 255)",
                "rgb(255, 159, 64)",
              ],
              borderColor: [
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
              ],
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },

    graficovend() {
      let me = this;
      var ctx = document.getElementById("myChart3");
      var myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: me.nombrevendedor,
          datasets: [
            {
              label: "Top 10",
              data: me.totalvendedor,
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 206, 86)",
                "rgb(39, 174, 96)",
                "rgb(54, 162, 235)",
                "rgb(255, 206, 86)",
                "rgb(75, 192, 192)",
                "rgb(153, 102, 255)",
                "rgb(255, 159, 64)",
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 206, 86)",
                "rgb(75, 192, 192)",
                "rgb(153, 102, 255)",
                "rgb(255, 159, 64)",
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 206, 86)",
              ],
              borderColor: [
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
              ],

              borderSkipped: false,
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },

    loadVentasAnuales() {
      let me = this;
      let mesn = "";
      me.anoValores.map(function (x) {
        switch (parseInt(x.etiqueta)) {
          case 1:
            mesn = "Enero";
            break;
          case 2:
            mesn = "Febrero";
            break;
          case 3:
            mesn = "Marzo";
            break;
          case 4:
            mesn = "Abril";
            break;
          case 5:
            mesn = "Mayo";
            break;
          case 6:
            mesn = "Junio";
            break;
          case 7:
            mesn = "Julio";
            break;
          case 8:
            mesn = "Agosto";
            break;
          case 9:
            mesn = "Setiembre";
            break;
          case 10:
            mesn = "Octubre";
            break;
          case 11:
            mesn = "Noviembre";
            break;
          case 12:
            mesn = "Diciembre";
            break;
          default:
            mesn = "Error";
        }
        me.nombreano.push(mesn);
        me.totalano.push(x.valor);
        me.TAnual = me.TAnual + x.valor;
        if (me.Fecha.getMonth() + 1 == x.etiqueta) {
          me.TMes = x.valor;
        }
      });

      var ctx = document.getElementById("myChart");
      var myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: me.nombreano,
          datasets: [
            {
              label: "Ventas en los últimos 12 Meses",
              data: me.totalano,
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 206, 86)",
                "rgb(39, 174, 96)",
                "rgb(230, 126, 34)",
                "rgb(218, 247, 166)",
                "rgb(125, 60, 152)",
                "rgb(217, 136, 128)",
                "rgb(245, 183, 177)",
                "rgb(84, 153, 199)",
                "rgb(118, 215, 196)",
                "rgb(255, 206, 86)",
                "rgb(75, 192, 192)",
                "rgb(153, 102, 255)",
                "rgb(255, 159, 64)",
              ],
              borderColor: [
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 2,
              borderRadius: 0,
              borderSkipped: false,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            tooltip: {
              mode: "index",
              intersect: false,
            },
          },
          hover: {
            mode: "index",
            intersec: false,
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Mes",
              },
            },
            y: {
              title: {
                display: true,
                text: "Monto",
              },
            },
          },
        },
      });
      this.Ventasfechaclientes();
      this.VentasProductosMeses12();
    },
    loadVentasAnuales() {
      let me = this;
      let mesn = "";
      me.anoValores.map(function (x) {
        switch (parseInt(x.etiqueta)) {
          case 1:
            mesn = "Enero";
            break;
          case 2:
            mesn = "Febrero";
            break;
          case 3:
            mesn = "Marzo";
            break;
          case 4:
            mesn = "Abril";
            break;
          case 5:
            mesn = "Mayo";
            break;
          case 6:
            mesn = "Junio";
            break;
          case 7:
            mesn = "Julio";
            break;
          case 8:
            mesn = "Agosto";
            break;
          case 9:
            mesn = "Setiembre";
            break;
          case 10:
            mesn = "Octubre";
            break;
          case 11:
            mesn = "Noviembre";
            break;
          case 12:
            mesn = "Diciembre";
            break;
          default:
            mesn = "Error";
        }
        me.nombreano.push(mesn);
        me.totalano.push(x.valor);
        me.TAnual = me.TAnual + x.valor;
        if (me.Fecha.getMonth() + 1 == x.etiqueta) {
          me.TMes = x.valor;
        }
      });
      var ctx = document.getElementById("myChart");
      var myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: me.nombreano,
          datasets: [
            {
              label: "Ventas en los últimos 12 Meses",
              data: me.totalano,
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 206, 86)",
                "rgb(39, 174, 96)",
                "rgb(230, 126, 34)",
                "rgb(218, 247, 166)",
                "rgb(125, 60, 152)",
                "rgb(217, 136, 128)",
                "rgb(245, 183, 177)",
                "rgb(84, 153, 199)",
                "rgb(118, 215, 196)",
                "rgb(255, 206, 86)",
                "rgb(75, 192, 192)",
                "rgb(153, 102, 255)",
                "rgb(255, 159, 64)",
              ],
              borderColor: [
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 2,
              borderRadius: 0,
              borderSkipped: false,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            tooltip: {
              mode: "index",
              intersect: false,
            },
          },
          hover: {
            mode: "index",
            intersec: false,
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Mes",
              },
            },
            y: {
              title: {
                display: true,
                text: "Monto",
              },
            },
          },
        },
      });
      this.Ventasfechaclientes();
      this.VentasProductosMeses12();
    },
  },
};
</script>
<style class="cp-pen-styles" scoped>
body {
  background-color: #f2f2f2;
  font-size: 13px;
}

h6 {
  font-size: 1.5rem;
  margin-top: 0;
}

p {
  margin: 0;
}

i.large {
  font-size: 48px;
}

.card,
.card-panel {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.container-fluid {
  padding: 1rem 1.5rem;
  margin: auto;
}

.row {
  margin: 0 -0.75rem;
}

.subheader {
  margin-bottom: 1rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
}

@media only screen and (min-width: 993px) {
  .main {
    padding-left: 250px;
  }
}
/* nav */
nav {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}
nav ul a {
  padding: 0 12px;
}
nav ul a img {
  line-height: 56px;
  vertical-align: middle;
}

nav,
nav .nav-wrapper i,
.navbar-fixed {
  height: 56px;
  line-height: 56px;
}

.nav-wrapper ul a:hover {
  background-color: transparent;
}
.nav-wrapper li:first-child a {
  padding-left: 24px;
}
.nav-wrapper .title {
  font-size: 1.4rem;
}

/* sidenav */
.side-nav .divider {
  margin: 8px 0;
}
.side-nav .active {
  background-color: rgba(0, 0, 0, 0.05);
}
.side-nav .active a {
  color: #212121;
  font-weight: 500;
}
.side-nav .subheader {
  line-height: 24px;
  height: 32px;
  margin: 0;
  padding: 4px 16px;
  color: #616161;
  font-weight: normal;
  font-size: 13px;
}

@media only screen and (min-width: 993px) {
  .side-nav {
    width: 250px;
    top: 66px;
    padding: 12px 8px !important;
    height: calc(100% - 56px);
  }
  .side-nav li > a,
  .side-nav li > a > i.material-icons {
    height: 40px;
    line-height: 40px;
  }
  .side-nav li > a > i.material-icons {
    margin-right: 24px;
  }
  .side-nav li > a {
    padding: 0 16px;
    font-weight: normal;
    font-size: 13px;
    color: #616161;
  }
  .side-nav li > a:hover {
    border-radius: 2px;
  }
}
/* cards */
.card .card-action {
  border-top: none;
}

.card .card-action a {
  color: #fff !important;
}

table {
  font-size: 13px;
}

table td:first-of-type,
table th:first-of-type {
  padding-left: 24px;
}

table td:last-of-type,
table th:last-of-type {
  padding-right: 24px;
}

table.bordered > thead,
table.bordered > tbody > tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.card table tr:last-child {
  border-bottom: none;
}

.card-text {
  width: calc(100% - 60px);
}

.card-icon i {
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.25);
}
.Seleccion {
  margin-bottom: 10px;
  padding: 10;
}
.logo {
  margin-left: 2%;
  margin-right: 2%;
  width: 500px;
  height: 500px;
}

.rounded {
  border-radius: 30px;
}
.ligne {
  border-right: solid 1px #95a5a6;
}
</style>