<template>
  <v-data-table :headers="headers" :items="productos" class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-btn @click="crearPDF()"><v-icon>print</v-icon></v-btn>
        <v-toolbar-title>Productos</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          @keypress.enter="listar()"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-btn @click="listar()" color="primary" dark class="mb-2"
          >Buscar</v-btn
        >
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="1000px" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="limpiar()"
            >
              Nuevo
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row no-gutters>
                  <v-col cols="6" sm="6">
                    <v-text-field
                      v-model="editedItem.Codigo"
                      :disabled="campo_bloqueado"
                      label="codigo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="IdCategoria"
                      :items="categorias"
                      label="Categoria"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="editedItem.Nombre"
                    label="Nombre"
                  ></v-text-field>
                </v-col>
                <v-row no-gutters>
                  <v-col cols="12" sm="6">
                    <v-card class="pa-2" outlined tile>
                      <h5>Existencias:</h5>
                      <vue-numeric
                        v-model="editedItem.Stock"
                        :disabled="campo_bloqueado"
                        Anonymous
                        separator=","
                        label="stock"
                      ></vue-numeric>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-card class="pa-2" outlined tile>
                      <h5>Precio De Venta:</h5>
                      <vue-numeric
                        v-bind:precision="2"
                        currency="L"
                        separator=","
                        v-model="editedItem.Precio_Venta"
                        label="Precio_Venta"
                      ></vue-numeric>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-tabs
                    background-color="cyan"
                    dark
                    next-icon="keyboard_arrow_right"
                    prev-icon="keyboard_arrow_left"
                    show-arrows
                  >
                    <v-tab>Inventario</v-tab>
                    <v-tab>Imagen</v-tab>
                    <v-tab>Productos Relacionados</v-tab>
                    <v-tab>Productos Equivalente</v-tab>
                    <v-tab-item>
                      <v-card-title> Minimos Por almacen </v-card-title>

                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Agregar Minimo
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row>
                              <v-flex xs12 sm12 md12 lg12 xl12>
                                <v-card class="pa-2" outlined tile>
                                  <v-row no-gutters>
                                    <v-col cols="12" sm="6">
                                      <v-select
                                        v-model="editedItem.idalmacen"
                                        :items="Almacenes"
                                        label="Almacen"
                                      >
                                      </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <v-text-field
                                        class="rigth-input"
                                        justify-right
                                        v-model="editedItem.cantidadminimotmp"
                                        type="number"
                                        label="Cantidad Minima"
                                      >
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                      <v-select
                                        v-model="editedItem.idequipomodelo"
                                        :items="modelos"
                                        label="Modelos de Maquinaria"
                                      >
                                      </v-select>
                                    </v-col>

                                    <v-flex
                                      xs12
                                      sm12
                                      md12
                                      lg12
                                      xl12
                                      v-if="errorMinimo"
                                    >
                                      <div
                                        class="red--text"
                                        v-text="errorMinimo"
                                      ></div>
                                    </v-flex>
                                    <v-col cols="12" sm="12">
                                      <v-btn
                                        @click="agregardetallesminimos"
                                        block
                                        elevation="4"
                                        color="accent"
                                        >Agregar al Detalle</v-btn
                                      >
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-flex>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
<v-card>
                      <v-row>
                        <v-card-title>Minimos Definidos</v-card-title>
                        <v-col cols="12" sm="12">
                          <v-data-table
                            :headers="cabeceraMinimos"
                            :items="Minimos"
                            hide-default-footer
                            disable-pagination
                            class="elevation-1"
                          >
                            <template
                              v-slot:[`item.borrar`]="{ item }"
                              class="justify-center layout px-0"
                            >
                              <v-icon
                                small
                                class="mr-2"
                                @click="eliminardetalle(detalles, item)"
                              >
                                delete
                              </v-icon>
                            </template>
                            <template v-slot:[`item.cantidad`]="{ item }">
                              <v-text-field
                                min="1"
                                v-model="item.cantidad"
                                type="number"
                                pattern="^[0-9]+"
                              >
                              </v-text-field>
                            </template>
                            <template slot="no-data">
                              <h3>No hay artículos agregados al detalle.</h3>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                      </v-card>
                    </v-tab-item>

                    <v-tab-item >
                      <!--
                      <v-flex xs12 sm12 md12 lg12 xl12>
                        <v-btn @click="obtenerimg()">Obtener Imagen</v-btn>
                      </v-flex>

                      <v-spacer></v-spacer>
!-->
                      <input
                        id="tfile"
                        type="file"
                        accept="image/jpeg/*"
                        @change="uploadImage()"
                      />

                        
                      

                      <div style="width: 400px; height: 400px" id="imgproducto">
                         <img
                                    height="300px"
                                    width="300px"
                                    :src="`${imageSrc}`"
                                  />
                      </div>

                    </v-tab-item>

                    <v-tab-item>
                      <v-flex xs12 sm8 md8 lg8 xl8>
                        <v-text-field
                          @keyup.enter="buscarcodigo(1)"
                          v-model="codigo"
                          label="Código"
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12 xl12 v-if="errorArticulo">
                        <div class="red--text" v-text="errorArticulo"></div>
                      </v-flex>
                      <v-data-table
                        :headers="cabeceraDetalles"
                        :items="detalles"
                        hide-default-footer
                        disable-pagination
                        class="elevation-1"
                      >
                        <template
                          v-slot:[`item.borrar`]="{ item }"
                          class="justify-center layout px-0"
                        >
                          <v-icon
                            small
                            class="mr-2"
                            @click="eliminardetalle(detalles, item)"
                          >
                            delete
                          </v-icon>
                        </template>
                        <template v-slot:[`item.cantidad`]="{ item }">
                          <v-text-field
                            min="1"
                            v-model="item.cantidad"
                            type="number"
                          >
                          </v-text-field>
                        </template>
                        <template slot="no-data">
                          <h3>No hay artículos agregados al detalle.</h3>
                        </template>
                      </v-data-table>
                    </v-tab-item>
                    

                    <v-tab-item>
                      <v-flex xs12 sm8 md8 lg8 xl8>
                        <v-text-field
                          @keyup.enter="buscarcodigo(2)"
                          v-model="codigo"
                          label="Código"
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12 xl12 v-if="errorArticulo">
                        <div class="red--text" v-text="errorArticulo"></div>
                      </v-flex>
                      <v-data-table
                        :headers="cabeceraEquivalente"
                        :items="detallesequivalentes"
                        hide-default-footer
                        disable-pagination
                        class="elevation-1"
                      >
                        <template
                          v-slot:[`item.borrar`]="{ item }"
                          class="justify-center layout px-0"
                        >
                          <v-icon
                            small
                            class="mr-2"
                            @click="eliminardetalle(detallesequivalentes, item)"
                          >
                            delete
                          </v-icon>
                        </template>
                        <template v-slot:[`item.cantidad`]="{ item }">
                          <v-text-field
                            min="1"
                            v-model="item.cantidad"
                            type="number"
                          >
                          </v-text-field>
                        </template>
                        <template slot="no-data">
                          <h3>No hay artículos agregados al detalle.</h3>
                        </template>
                      </v-data-table>
                    </v-tab-item>
                  </v-tabs>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-text>
              <v-container>
                <v-text-field
                  class="red--text"
                  v-for="v in validamensaje"
                  :key="v"
                  v-text="v"
                >
                </v-text-field>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="Guardar">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="addmodal" max-width="500px">
          <v-card>
            <v-card-title class="headline" v-if="addaction == 1">
              Desea Activar
            </v-card-title>
            <v-card-title class="headline" v-if="addaction == 2">
              Desea Desactivar
            </v-card-title>

            <v-card-text> Estas a Punto de: </v-card-text>
            <span v-if="addaction == 1"> Activar </span>
            <span v-if="addaction == 2"> Desactivar</span>
            el item {{ addnombre }}
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="activardesactivarcerrar"
                >Cancel</v-btn
              >
              <v-btn
                v-if="addaction == 1"
                color="blue darken-1"
                text
                @click="activar"
                >Activar</v-btn
              >
              <v-btn
                v-if="addaction == 2"
                color="blue darken-1"
                text
                @click="desactivar"
                >Desactivar</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> edit </v-icon>
      <template v-if="item.condicion">
        <v-icon small @click="activardesacticarmostrar(2, item)">
          block
        </v-icon>
      </template>
      <template v-else>
        <v-icon small @click="activardesacticarmostrar(1, item)">
          check
        </v-icon>
      </template>
    </template>

    <template v-slot:[`item.condicion`]="{ item }">
      <div v-if="item.condicion">
        <span class="blue--text">Activo</span>
      </div>
      <div v-else>
        <span class="red--text">Inactivo</span>
      </div>
    </template>

    <template v-slot:no-data>
      <v-btn
        loading
        loading-text="Loading... Please wait"
        color="primary"
        @click="listar"
      >
        aaaaaaaaaaaaaaaaaaaaa
      </v-btn>
    </template>
  </v-data-table>
</template>


<script>
import axios from "axios";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export default {
  data: () => ({
    dialog: false,
    search: "",
    dialogDelete: false,
    productos: [],
    headers: [
      { text: "Acciones", value: "actions", sortable: false },
      { text: "IdProducto", value: "idproducto", sortable: false },
      { text: "Codigo", value: "producto", sortable: false },
      { text: "Nombre", value: "nombre" },
      { text: "Categoria", value: "ncategoria" },
      { text: "Existencias", value: "stock" },
      { text: "Precio", value: "precio_venta" },
      { text: "Condicion", value: "condicion" },
    ],

    cabeceraDetalles: [
      { text: "Borrar", value: "borrar", sortable: false },
      { text: "Codigo", value: "producto", sortable: false },
      { text: "Nombre", value: "nproducto", sortable: false },
      { text: "Cantidad", value: "cantidad", sortable: false },
    ],

    cabeceraMinimos: [
      { text: "Borrar", value: "borrar", sortable: false },
      { text: "Nombre", value: "nalmacen", sortable: false },
      { text: "Cantidad", value: "cantidad", sortable: false },
      { text: "Modelo Asociado", value: "nequipomodelo", sortable: false },
    ],
    Almacenes: [],
    idalmacen: "",
    Minimos: [],
    modelos: [],
    cantidadminimotmp: "",
    cabeceraEquivalente: [
      { text: "Borrar", value: "borrar", sortable: false },
      { text: "Codigo", value: "producto", sortable: false },
      { text: "Nombre", value: "nproducto", sortable: false },
    ],
    detalles: [],
    detallesequivalentes: [],
    editedIndex: -1,
    editedItem: {
      Codigo: "",
      Nombre: "",
      Stock: 0,
      Precio_Venta: 0,
    },
    IdCategoria: "",
    IdProducto: "",
    categorias: [],
    valida: 0,
    validamensaje: [],
    addmodal: 0,
    addaction: 0,
    addnombre: "",
    adid: "",
    errorArticulo: null,
    errorMinimo: "",
    campo_bloqueado: false,
    imageSrc: "",
    codigo: "",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Producto" : "Editando Producto";
    },
  },

  created() {
    this.listar();
    this.SelectCategoria();
    this.SelectAlmacenes();
    this.SelectEquiposModelos();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    uploadImage() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();
      let rawImg;
      reader.onloadend = () => {
        this.imageSrc = reader.result;
        console.log(this.imageSrc);
       // this.llenardiv();
      };
      reader.readAsDataURL(file);
      // console.log(file)
      //alert(this.imageSrc);
    },

    sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },

    dowImage() {
      this.loading = true;
      let me = this;
      me.imageSrc = "";
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/Productos/ObtenerImg/" + this.IdProducto, configuracion)
        .then(function (response) {
          me.imageSrc = response.data["imgbase"];
          //alert(me.imageSrc)
          //alert("entro")
          if (me.imageSrc != null && me.imageSrc.length > 10) {
            //alert("paso")
            if (document.getElementById("id") != null) {
              var x = document.getElementById("id");
              document.getElementById("imgproducto").removeChild(x);
            }
            me.llenardiv();
            //document.getElementById("imgproducto").innerHTML = '&nbsp;';
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => (this.loading = false));
      //alert("termino Primero")
    },

    llenardiv() {
      let me = this;
      if (me.imageSrc == null || me.imageSrc.length < 10) {
        alert("No hay Imagen Disponible");
        return;
      }
      if (document.getElementById("id") != null) {
        var x = document.getElementById("id");
        document.getElementById("imgproducto").removeChild(x);
      }
      //document.getElementById("imgproducto").innerHTML = '&nbsp;';
      var image = document.createElement("img");
      var imageParent = document.getElementById("imgproducto");
      image.id = "id";
      image.className = "class";
      image.src = me.imageSrc; // image.src = "IMAGE URL/PATH"
      image.width = "300";
      image.height = "300";
      imageParent.appendChild(image);
    },

    crearPDF() {
      var columns = [
        { title: "Nombre", dataKey: "nombre" },
        { title: "Código", dataKey: "producto" },
        { title: "Categoría", dataKey: "ncategoria" },
        { title: "Stock", dataKey: "stock" },
        { title: "Precio Venta", dataKey: "precio_venta" },
      ];
      var rows = [];

      this.productos.map(function (x) {
        rows.push({
          nombre: x.nombre,
          producto: x.producto,
          ncategoria: x.ncategoria,
          stock: x.stock,
          precio_venta: x.precio_venta,
        });
      });

      // Only pt supported (not mm or in)
      var doc = new jsPDF("p", "pt");
      doc.autoTable(columns, rows, {
        margin: { top: 60 },
        addPageContent: function (data) {
          doc.text("Listado de Artículos", 40, 30);
        },
      });
      doc.save("Productos.pdf");
    },

    listar() {
      let me = this;
      let url = "";
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      if (me.search.length <= 0) { 
        url = "api/Productos/ListarSaldos";
      } else {
        url = "api/Productos/ListarIngreso/" + me.search;
      }
      axios
        .get(url, configuracion)
        .then(function (response) {
          me.productos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    SelectCategoria() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      var categiriasaray = [];
      axios
        .get("api/Categorias/Select", configuracion)
        .then(function (response) {
          categiriasaray = response.data;
          categiriasaray.map(function (x) {
            me.categorias.push({ text: x.nombre, value: x.idcategoria });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    SelectAlmacenes() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      var almacenesaray = [];
      axios
        .get("api/Almacenes/Select", configuracion)
        .then(function (response) {
          almacenesaray = response.data;
          almacenesaray.map(function (x) {
            me.Almacenes.push({ text: x.nombre, value: x.idalmacen });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    SelectEquiposModelos() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      var equiposmodelosaray = [];
      axios
        .get("api/EquiposModelos/Select", configuracion)
        .then(function (response) {
          equiposmodelosaray = response.data;
          equiposmodelosaray.map(function (x) {
            me.modelos.push({ text: x.nombre, value: x.idequipomodelo });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem(item) {
      //this.limpiar();
      this.IdProducto = item.idproducto;
      this.editedItem.Nombre = item.nombre;
      this.IdCategoria = item.idcategoria;
      this.editedItem.Codigo = item.producto;
      this.editedItem.Precio_Venta = item.precio_venta;
      this.editedItem.Stock = item.stock;
      this.editedIndex = 1;
      this.campo_bloqueado = true;
      this.imageSrc = item.imgbase;
      this.dialog = true;
      this.listardetalle(item.idproducto);
      this.listardetalleequivalente(item.idproducto);
      this.listardetalleminimos(item.idproducto);
      this.obtenerimg(item.idproducto)

      var inputImage = document.getElementById("tfile");
      if (inputImage != null) {
        inputImage.value = "";
      }

      //this.dowImage()
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.limpiar();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    limpiar() {
      this.detalles = [];
      this.detallesequivalentes = [];
      this.editedIndex = -1;
      this.editedItem.Nombre = "";
      this.IdCategoria = "";
      this.editedItem.idproducto = "";
      this.editedItem.Codigo = "";
      this.editedItem.Stock = "";
      this.editedItem.Precio_Venta = "";
      this.campo_bloqueado = false;
      if (this.imageSrc != null && this.imageSrc.length > 10) {
        if (document.getElementById("id") != null) {
          var x = document.getElementById("id");
          document.getElementById("imgproducto").removeChild(x);
        }
      }
      this.imageSrc = "";
    },

    validar() {
      this.valida = 0;
      this.validamensaje = [];
      if (
        this.editedItem.Nombre.length < 3 ||
        this.editedItem.Nombre.length > 50
      ) {
        this.validamensaje.push(
          "*El nombre debe tener mas de 3 Caracteres y Menos de 50 caracteres."
        );
      }

      if (
        this.editedItem.Codigo.length < 3 ||
        this.editedItem.Codigo.length > 20
      ) {
        this.validamensaje.push(
          "*El Codigo debe tener mas de 3 Caracteres y Menos de 20 caracteres."
        );
      }

      if (!this.IdCategoria || this.IdCategoria === "") {
        this.validamensaje.push("*Selecione una Categoria.");
      }

      if (
        !this.editedItem.Precio_Venta ||
        this.editedItem.Precio_Venta.value <= 0
      ) {
        this.validamensaje.push("*El Precio no puede ser menor o igual a 0.");
      }

      if (this.validamensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },

    activardesacticarmostrar(accion, item) {
      this.addmodal = 1;
      this.addnombre = item.nombre;
      this.adid = item.idproducto;
      if (accion == 1) {
        this.addaction = 1;
      } else if (accion == 2) {
        this.addaction = 2;
      } else {
        this.addmodal = 0;
      }
    },

    activar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put("api/Productos/Activar/" + this.adid, {}, configuracion)
        .then(function (response) {
          me.addmodal = 0;
          me.addaction = 0;
          me.addnombre = "";
          me.adid = "";
          me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    desactivar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put("api/Productos/DesActivar/" + this.adid, {}, configuracion)
        .then(function (response) {
          me.addmodal = 0;
          me.addaction = 0;
          me.addnombre = "";
          me.adid = "";
          me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    activardesactivarcerrar() {
      this.addmodal = 0;
    },

    buscarcodigo(valor) {
      let me = this;
      this.errorArticulo = null;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/Productos/BuscarCodigo/" + this.codigo, configuracion)
        .then(function (response) {
          // console.log(response);
          if (valor == 1) {
            me.agregardetalle(response.data);
          } else {
            me.agregardetallesequivalentes(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
          me.errorArticulo = "Articulo No encontrado";
        });
      this.codigo = "";
    },

    agregarcliente(data = []) {
      //this.errorArticulo=null;
      (this.ncliente = data["nombre"]),
        (this.idcliente = data["idcliente"]),
        (this.texto = ""),
        this.ocultarclintes();
    },

    agregardetalle(data = []) {
      if (data["idproducto"].length <= 0) {
        this.errorArticulo = "Ingrese un Valor";
        return;
      }
      this.errorArticulo = null;
      if (this.encuentra(data["idproducto"])) {
        this.errorArticulo = "Ya se encuentra agregado";
      } else {
        let me = this;
        this.detalles.push({
          idproducto: data["idproducto"],
          producto: data["producto"],
          nproducto: data["nombre"],
          cantidad: 1,
        });
      }
    },

    agregardetallesequivalentes(data = []) {
      if (data["idproducto"].length <= 0) {
        this.errorArticulo = "Ingrese un Valor";
        return;
      }
      this.errorArticulo = null;
      if (this.encuentraequivalente(data["idproducto"])) {
        this.errorArticulo = "Ya se encuentra agregado";
      } else {
        let me = this;
        me.detallesequivalentes.push({
          idproducto: data["idproducto"],
          producto: data["producto"],
          nproducto: data["nombre"],
          cantidad: 1,
        });
      }
    },

    encuentra(id) {
      var sw = 0;
      for (var i = 0; i < this.detalles.length; i++) {
        if (this.detalles[i].idproducto == id) {
          sw = 1;
        }
      }
      return sw;
    },

    encuentraequivalente(id) {
      var sw = 0;
      for (var i = 0; i < this.detallesequivalentes.length; i++) {
        if (this.detallesequivalentes[i].idproducto == id) {
          sw = 1;
        }
      }
      return sw;
    },

    agregardetallesminimos() {
      if (
        this.editedItem.idalmacen.length <= 0 ||
        this.editedItem.idequipomodelo.length <= 0
      ) {
        this.errorMinimo = "Ingrese un Valor";
        return;
      }
      this.errorMinimo = null;
      if (this.encuentraminimo(this.editedItem.idalmacen)) {
        this.errorMinimo = "Ya se encuentra agregado el almacen";
      } else {
        let me = this;

        me.Minimos.push({
          idproducto: this.IdProducto,
          idalmacen: this.editedItem.idalmacen,
          nalmacen: this.Almacenes[this.editedItem.idalmacen - 1].text,
          idequipomodelo: this.editedItem.idequipomodelo,
          nequipomodelo: this.modelos[this.editedItem.idequipomodelo - 1].text,
          cantidad: this.editedItem.cantidadminimotmp,
        });
      }
    },

    encuentraminimo(id) {
      var sw = 0;
      for (var i = 0; i < this.Minimos.length; i++) {
        if (this.Minimos[i].idalmacen == id) {
          sw = 1;
        }
      }
      return sw;
    },

    eliminardetalle(arr, item) {
      var i = arr.indexOf(item);
      if (i != -1) {
        arr.splice(i, 1);
      }
    },

    listardetalle(id) {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/productos/ProductosRelacionados/" + id, configuracion)
        .then(function (response) {
          me.detalles = response.data;
        })
        .catch(function (error) {
          console.log(error);
          alert("No podra Actualizar la Cotizacion");
        });
    },


    obtenerimg(id){
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/productos/ObtenerImg/" + id, configuracion)
        .then(function (response) {
         // alert(response.data['imgbase']);
          me.imageSrc = response.data['imgbase'];
        })
        .catch(function (error) {
          console.log(error);
          alert("No podra Actualizar la imagen");
        });
    },

    listardetalleequivalente(id) {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/productos/ProductosEquivalentes/" + id, configuracion)
        .then(function (response) {
          me.detallesequivalentes = response.data;
        })
        .catch(function (error) {
          console.log(error);
          alert("No podra Actualizar la Cotizacion");
        });
    },


    listardetalleminimos(id) {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/productos/ProductosMinimos/" + id, configuracion)
        .then(function (response) {
          me.Minimos = response.data;
        })
        .catch(function (error) {
          console.log(error);
          alert("No podra Actualizar la Cotizacion");
        });
    },


    Guardar() {
      let ma = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      if (ma.validar()) {
        return;
      }

      if (this.editedIndex > -1) {
        //actualizar
        let me = this.editedItem;
        axios
          .put(
            "api/Productos/Actualizar",
            {
              idproducto: this.IdProducto,
              idcategoria: this.IdCategoria,
              producto: me.Codigo,
              nombre: me.Nombre,
              precio_venta: me.Precio_Venta,
              imgbase: this.imageSrc,
              productosrelacionadosdetalle: this.detalles,
              productosequivalentesdetalle: this.detallesequivalentes,
              productosminimosdetalle: this.Minimos,
            },
            configuracion
          )
          .then(function (response) {
            ma.close();
            ma.listar();
            ma.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        let me = this.editedItem;
        axios
          .post(
            "api/Productos/Crear",
            {
              producto: me.Codigo,
              idcategoria: this.IdCategoria,
              nombre: me.Nombre,
              precio_venta: me.Precio_Venta,
              imgbase: this.imageSrc,
              productosrelacionadosdetalle: this.detalles,
              productosequivalentesdetalle: this.detallesequivalentes,
            },
            configuracion
          )
          .then(function (response) {
            ma.close();
            ma.listar();
            ma.limpiar();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
#imagen {
  margin-top: 10px;
  width: "400px";
  height: "400px";
}
.rigth-input >>> input {
  text-align: right;
}
</style>