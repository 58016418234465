<template>
    <v-data-table
      :headers="headers"
      :items="productos"  
      :search="search"    
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >

          <v-toolbar-title>Consultar Minimos</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical 
          ></v-divider>
          
          <v-text-field @keypress.enter="obtenerminimos()" class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
                <v-checkbox
        v-model="fat"
      ></v-checkbox>
      
          <v-btn
                color="primary"
                dark
                class="mb-2"
                
                @click="obtenerminimos()"
              >
                Consultar
              </v-btn>

          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >

            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text

                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
        <template
          v-slot:[`item.diferencia`]="{ item }">
        <div v-if="item.cantidad-item.stock>=1">
          <span class="red--text">{{ new Intl.NumberFormat("en-US").format(item.cantidad-item.stock)}}</span>
        </div>
        <div v-else>
          <span class="blue--text">0</span>
        </div>
      
                    </template>
                    <template
          v-slot:[`item.costototal`]="{ item }">
        <div v-if="(item.cantidad-item.stock)>=0">
          <span class="red--text">{{ new Intl.NumberFormat("en-US").format((item.cantidad-item.stock)*item.costobase)}}</span>
        </div>
        <div v-else>
          <span class="blue--text">0</span>
        </div>
      
                    </template>

      <template v-slot:no-data
      >
       <h3>No hay artículos para Mostrar.</h3>
        <v-dialog v-model="loading" max-width="500px">

            <v-btn 
            loading
            loading-text="Loading... Please wait"
              color="primary"
              @click="listar"
            >
              aaaaaaaaaaaaaaaaaaaaa
            </v-btn>
        </v-dialog>
      </template>
    </v-data-table>
</template>


<script>

import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export default {

  data: () => ({
    dialog: false,
    search:'',
    dialogDelete: false,
    productos:[],
           fat: '',
    headers: [
      { text: 'Codigo', value: 'producto',  sortable: false},
      { text: 'Nproducto', value: 'nproducto' },
      { text: 'Almacen', value: 'nalmacen' },
      { text: 'Modelo', value: 'nequipomodelo'},
      { text: 'Minimo', value: 'cantidad'},
      { text: 'Existencias', value: 'stock'},
      { text: 'Diferencia', value: 'diferencia'},
      { text: 'CostoUni', value: 'costobase'},
      { text: 'Costo-Total', value: 'costototal'},
      
    ],
    editedIndex: -1,
    editedItem: {
      Codigo:'',
      Nombre: '',
      Stock: 0,
      Precio_Venta: 0,
    },
    checkedNames: [],
    IdCategoria:'',
    IdProducto: '',
    loading:false,
    categorias:[],
      valida:0,
      validamensaje:[],
      addmodal:0,
      addaction:0,
      addnombre:'',
      adid:'',
      campo_bloqueado:false
  }),
  


 computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Producto' : 'Editando Producto'
    }
    
    
  },

  created () {
    //this.obtenerprecioexistencias()
    //this.SelectCategoria()
    this.obtenerminimos()
 
  },

   watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
    methods: {
            crearPDF(){
                var columns = [
                    {title: "Nombre", dataKey: "nombre"},
                    {title: "Código", dataKey: "producto"}, 
                    {title: "Categoría", dataKey: "ncategoria"}, 
                    {title: "Stock", dataKey: "stock"},
                    {title: "Precio Venta", dataKey: "precio_venta"}
                ];
                var rows = [];

                this.productos.map(function(x){
                    rows.push({nombre:x.nombre,producto:x.producto,ncategoria:x.ncategoria,stock:x.stock,precio_venta:x.precio_venta});
                });

                // Only pt supported (not mm or in)
                var doc = new jsPDF('p', 'pt');
                doc.autoTable(columns, rows, {
                    margin: {top: 60},
                    addPageContent: function(data) {
                        doc.text("Listado de Artículos", 40, 30);
                    }
                });
                doc.save('Productos.pdf');
            },

        listar(){
                let me=this;
                let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};
                if(this.fat){
                axios.get('api/Productos/Listar',configuracion).then(function(response){
                    me.productos=response.data;
                }).catch(function(error){
                    console.log(error);
                });

                }
                else
                {
                axios.get('api/Productos/Listar',configuracion).then(function(response){
                    me.productos=response.data;
                }).catch(function(error){
                    console.log(error);
                });
                }
            },


    close () {
      this.dialog = false;
      this.limpiar();
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

activar () {
  let me=this;
  let header={"Authorization":"Bearer "+this.$store.state.token};
  let configuracion={headers:header};
      axios.put('api/Productos/Activar/'+this.adid,{},configuracion).then(function(response)
          {
            me.addmodal=0;
            me.addaction=0;
            me.addnombre="";
            me.adid="";
            me.listar();

          }).catch(function(error)
          {
            console.log(error);
          });
  
    },

activardesactivarcerrar () {
      this.addmodal=0;
    },


 obtenerminimos()
          {
          this.loading=true;
           let me=this;    
           //me.existenciasuni=0;   
           let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};
                axios.get('api/productos/ProductosMinimoslistar',configuracion).then(function(response){
                    me.productos=response.data;
                   // me.mostrarexistencias();
                }).catch(function(error){
                    console.log(error);
                    alert("Error al Consultar Producto")
                }).finally(() => this.loading = false);
          },

  }, 

  filterFat(item) {
       return item.cantidad>1;
     }


  
}
</script>









