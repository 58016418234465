
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Categoria from '../components/Categoria.vue'
import Producto from '../components/Producto.vue'
import VueMask from 'v-mask'
import VueNumeric from 'vue-numeric'
import Rol from '../components/Rol.vue'
import Usuario from '../components/Usuario.vue'
import Departamento from '../components/Departamento.vue'
import Ciudad from '../components/Ciudad.vue'
import Clasificacion from '../components/Clasificacion.vue'
import Vendedor from '../components/Vendedor.vue'
import Cliente from '../components/Cliente.vue'
import Login from '../components/Login.vue'
import store from '../store'
import Almacen from '../components/Almacen.vue'
import Cotizacion from '../components/Cotizacion.vue'
import GraficaVentas from '../components/GraficaVentas.vue'
import ConsultaExistencias from '../components/ConsultaExistencias.vue'
import ConsultaVentas from '../components/ConsultaVentas.vue'
import EquipoMarca from '../components/EquipoMarca.vue'
import EquipoModelo from '../components/EquipoModelo.vue'
import ConsultaMinimos from '../components/ConsultaMinimos.vue'
import dashboardfinancieros from '../components/dashboardfinanciero.vue'
import Ubicaciones from '../components/Ubicacion.vue'
import Censosclientes from '../components/CensoCliente.vue'
import Documentos from '../components/Documentos.vue'
import ClasiProovedor from '../components/ClasiProveedor.vue'
import Proveedor from '../components/Proveedor.vue'
import Periodo from '../components/CntPeriodo.vue'
import GrupoProyecto from '../components/GrupoProyecto.vue'
import Proyecto from '../components/Proyecto.vue'
import SerieDocumento from '../components/SerieDocumento.vue'
import Cajero from '../components/Cajero.vue'
import BanConcepto from '../components/BanConcepto.vue'
import ConceptoCXC from '../components/ConceptoCXC.vue'
import DocumentoCXC from '../components/DocumentoCXC.vue'




Vue.use(VueRouter)
Vue.use(VueMask)
Vue.use(VueNumeric)
const routes =  [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta :{
      administrador :true,
      bodega: true,
      vendedores: true,
      compradores: true,
      contabilidad: true
    }
  },
  {
    path: '/categorias',
    name: 'categorias',
    component: Categoria,
    meta :{
      administrador :true,
      bodega: true,
    }
  },
  {
    path: '/productos',
    name: 'productos',
    component: Producto,
    meta :{
      administrador :true,
      bodega: true
    }
  },
  {
    path: '/cajeros',
    name: 'cajeros',
    component: Cajero,
    meta :{
      administrador :true,
    }
  },,
  
  {
    path: '/roles',
    name: 'roles',
    component: Rol,
    meta :{
      administrador :true
    }
  },
  
  {
    path: '/usuarios',
    name: 'usuarios',
    component: Usuario,
    meta :{
      administrador :true
    }

  },
  
  {
    path: '/departamentos',
    name: 'departamentos',
    component: Departamento,
    meta :{
      administrador :true
    }
  },
  {
    path: '/ciudades',
    name: 'ciudades',
    component: Ciudad,
    meta :{
      administrador :true    }
  },
  {
    path: '/clasificaciones',
    name: 'clasificaciones',
    component: Clasificacion,
    meta :{
      administrador :true    }
  },
  {
    path: '/vendedores',
    name: 'vendedores',
    component: Vendedor,
    meta :{
      administrador :true
    }
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: Cliente,
    meta :{
      administrador :true
    }
  },

  {
    path: '/Almacenes',
    name: 'Almacenes',
    component: Almacen,
    meta :{
      administrador :true,
      bodega: true
    }
  },

  {
    path: '/Ubicaciones',
    name: 'Ubicaciones',
    component: Ubicaciones,
    meta :{
      administrador :true,
      bodega: true
    }
  },

  {
    path: '/cotizaciones',
    name: 'cotizaciones',
    component: Cotizacion,
    meta :{
      administrador :true,
      bodega: true,
      vendedores: true,
      compradores: true
    }
  },

  {
    path: '/login',
    name: 'login',
    component: Login,
    meta : {
      libre: true
    }
  },

  {
    path: '/graficaventas',
    name: 'graficaventas',
    component: GraficaVentas,
    meta :{
      administrador :true,
    }
  },
  
  {
    path: '/consultaexistencias',
    name: 'consultaexistencias',
    component: ConsultaExistencias,
    meta :{
      administrador :true,
      vendedores: true,
      bodega: true,
      compradores: true
    }
  },

  {
    path: '/consultaventas',
    name: 'consultaventas',
    component: ConsultaVentas,
    meta :{
      administrador :true,
    }
  },

  {
    path: '/equipomarcas',
    name: 'equipomarcas',
    component: EquipoMarca,
    meta :{
      administrador :true,
      vendedores: true,
      bodega: true
    }
  },

  {
    path: '/consultaminimos',
    name: 'consultaminimos',
    component: ConsultaMinimos,
    meta :{
      administrador :true,
      bodega: true
    }
  },

  
  {
    path: '/dashboardfinancieros',
    name: 'dashboardfinancieros',
    component: dashboardfinancieros,
    meta :{
      administrador :true,
    }
  },
  {
    path: '/documentos',
    name: 'documentos',
    component: Documentos,
    meta :{
      administrador :true,
      vendedores: true,
      bodega: true,
      compradores: true
    }
  },
  
  {
    path: '/equiposmodelos',
    name: 'equiposmodelos',
    component: EquipoModelo,
    meta :{
      administrador :true,
      vendedores: true,
      compradores: true,
      bodega: true
    }
  },

  {
    path: '/ClasiProovedor',
    name: 'ClasiProovedor',
    component: ClasiProovedor,
    meta :{
      administrador :true,
      compradores: true
    }
  },
  {
    path: '/Proveedor',
    name: 'Proveedor',
    component: Proveedor,
    meta :{
      administrador :true,
      compradores: true
    }
  },


  {
    path: '/censosclientes',
    name: 'censosclientes',
    component: Censosclientes,
    meta :{
      administrador :true,
      vendedores: true,
      compradores: true,
      bodega: true
    }
  },
  {
    path: '/periodo',
    name: 'periodo',
    component: Periodo,
    meta :{
      administrador :true,
      contabilidad: true,
    }
  },

  {
    path: '/gruposproyectos',
    name: 'gruposproyectos',
    component: GrupoProyecto,
    meta :{
      administrador :true,
      contabilidad: true,
    }
  },

  {
    path: '/proyectos',
    name: 'proyectos',
    component: Proyecto,
    meta :{
      administrador :true,
      contabilidad: true,
    }
  },

  {
    path: '/seriedocumento',
    name: 'seriedocumento',
    component: SerieDocumento,
    meta :{
      administrador :true,
      contabilidad: true,
    }
  },
  {
    path: '/BanConcepto',
    name: 'BanConcepto',
    component: BanConcepto,
    meta :{
      administrador :true,
      contabilidad: true,
    }
  },
  {
    path: '/conceptocxc',
    name: 'conceptocxc',
    component: ConceptoCXC,
    meta :{
      administrador :true,
      contabilidad: true,
    }
  },

  {
    path: '/documentocxc',
    name: 'documentocxc',
    component: DocumentoCXC,
    meta :{
      administrador :true,
      contabilidad: true,
    }
  }
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)){
    next()
  } else if (store.state.usuario && store.state.usuario.rol== 'Administrador'){
    if (to.matched.some(record => record.meta.administrador)){
      next()
    }
  }else if (store.state.usuario && store.state.usuario.rol== 'Bodega'){
    if (to.matched.some(record => record.meta.almacenero)){
      next()
    }
  }
  else if (store.state.usuario && store.state.usuario.rol== 'Contabilidad'){
    if (to.matched.some(record => record.meta.contabilidad)){
      next()
    }
  }
  else if (store.state.usuario && store.state.usuario.rol== 'Compras'){
    if (to.matched.some(record => record.meta.compradores)){
      next()
    }
  }
  else if (store.state.usuario && store.state.usuario.rol== 'Vendedores'){
    if (to.matched.some(record => record.meta.vendedores)){
      next()
    }
    
  }else{
    next({
    name: 'login'
    })
  }
})

export default router
