<template>
  <hello-world />
</template>

<script>
  import HelloWorld from '../components/Home'
  import GraficaVentas from '../components/GraficaVentas'

  export default {
    name: 'Home',

    components: {
      HelloWorld,
    },
  }
</script>
