import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueTheMask from 'vue-the-mask'
import IdleVue from 'idle-vue'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(VueTheMask)
Vue.config.productionTip = true
//axios.defaults.baseURL='http://190.109.201.229/'
axios.defaults.baseURL='http://190.4.43.82/'
//axios.defaults.baseURL='http://localhost:54194'



const eventsHub = new Vue()
 
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 300000
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  data () {
    return {
      messageStr: 'Hello'
    }
  },
  onIdle() {
    this.$store.dispatch("salir");
  },
  onActive() {
    this.messageStr = 'Hello'
  }
}).$mount('#app')
