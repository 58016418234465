<template>
  <div>
    <v-toolbar-title
      >Consultar Existencias
      <v-radio-group v-model="row" row>
        <v-radio @click="limpiar()" label="Producto" value="1"></v-radio>
        <v-radio @click="limpiar()" label="Ubicacion" value="2"></v-radio>
      </v-radio-group>
    </v-toolbar-title>

    <v-data-table
      :headers="headers"
      :items="productos"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            @keypress.enter="obtenerprecioexistencias()"
            class="text-xs-center"
            v-model="search"
            append-icon="search"
            label="Búsqueda"
            single-line
            hide-details
          ></v-text-field>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="obtenerprecioexistencias()"
          >
            Consultar
          </v-btn>
          <div>
            
            <v-icon color="primary" @click="vercam()">flip </v-icon>

            <v-dialog v-model="dialogcam" max-width="1000px" persistent>
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Ingreso Por Camara
                </v-card-title>
                <v-card-text>
                  <div>
                    <p class="error">{{ error }}</p>
                    <StreamBarcodeReader
                      @decode="onDecode"
                      @init="onInit"
                      @loaded="onInit"
                    ></StreamBarcodeReader>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-icon color="error" @click="ocultarcam()">cancel </v-icon>
                  </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

          <v-btn v-if="productos.length>= 1" @click="crearPDF()"
            ><v-icon>print</v-icon></v-btn
          >

          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:no-data>
        <h3>No hay artículos para Mostrar.</h3>
        <v-dialog v-model="loading" max-width="500px">
          <v-btn
            loading
            loading-text="Loading... Please wait"
            color="primary"
            @click="listar"
          >
            aaaaaaaaaaaaaaaaaaaaa
          </v-btn>
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>


<script>
import axios from "axios";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";

import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  data: () => ({
    dialog: false,
    search: "",
    dialogDelete: false,
    productos: [],
    result: "",
    error: "",
    dialogcam: 0,
    headers: [
      { text: "Codigo", value: "producto", sortable: false },
      { text: "Ubicacion", value: "ubicacion" },
      { text: "Existencia", value: "existencias" },
      { text: "Minimo", value: "minimo" },
      { text: "Precio", value: "monto" },
      { text: "IdProducto", value: "idproducto" },
    ],
    editedIndex: -1,
    editedItem: {
      Codigo: "",
      Nombre: "",
      Stock: 0,
      Precio_Venta: 0,
    },
    row: "1",
    IdCategoria: "",
    IdProducto: "",
    loading: false,

    categorias: [],
    valida: 0,
    validamensaje: [],
    addmodal: 0,
    addaction: 0,
    addnombre: "",
    adid: "",
    campo_bloqueado: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Producto" : "Editando Producto";
    },
  },

  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    StreamBarcodeReader,
  },

  created() {
    //this.obtenerprecioexistencias()
    //this.SelectCategoria()
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    vercam() {
      this.dialogcam = 1;
    },

    ocultarcam() {
      this.dialogcam = 0;
    },

    crearPDF() {
      if (this.row == 1) {
        var columns = [
          { title: "Código", dataKey: "producto" },
          { title: "Ubicacion", dataKey: "ubicacion" },
          { title: "Existencia", dataKey: "existencias" },
          { title: "Precio", dataKey: "precio" },
        ];
        var rows = [];
        console.log(this.productos);
        this.productos.map(function (x) {
          rows.push({
            producto: x.producto,
            ubicacion: x.ubicacion,
            existencias: x.existencias,
            precio: x.monto,
          });
        });

        // Only pt supported (not mm or in)
        var doc = new jsPDF("p", "pt");
        doc.autoTable(columns, rows, {
          margin: { top: 60 },
          addPageContent: function (data) {
            doc.text("Listado de Existencia Producto", 40, 30);
          },
        });
        //doc.save("ExistenciaPro.pdf");
        doc.autoPrint();
        doc.output("dataurlnewwindow");
      } else {
        var columns = [
          { title: "Código", dataKey: "producto" },
          { title: "Existencia", dataKey: "existencias" },
          { title: "Minimo", dataKey: "minimo" },
          { title: "Maximo", dataKey: "maximo" },
        ];
        var rows = [];
        console.log(this.productos);
        this.productos.map(function (x) {
          rows.push({
            producto: x.producto,
            existencias: x.existencias,
            minimo: x.minimo,
            maximo: x.minimo * 2,
          });
        });

        var base64Str = this.textToBase64Barcode(this.search);
        // Only pt supported (not mm or in)
        var doc = new jsPDF("p", "pt");
        doc.autoTable(columns, rows, {
          margin: { top: 100 },
          addPageContent: function (data) {
            doc.text("Listado de Minimos Producto", 40, 30);
            doc.addImage(base64Str, "png", 40, 30, 180, 60);
          },
        });
        //doc.save("ExistenciaPro.pdf");
        doc.autoPrint();
        doc.output("dataurlnewwindow");
      }
    },

    textToBase64Barcode(text) {
      var canvas = document.createElement("canvas");
      JsBarcode(canvas, text, { format: "CODE39" });
      return canvas.toDataURL("image/png");
    },

    onDecode(result) {
      console.log(result);
      this.search=result
      this.ocultarcam()
      this.obtenerprecioexistencias()
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },

    listar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/Productos/Listar", configuracion)
        .then(function (response) {
          me.productos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    alerts() {
      alert("hola" + this.row);
    },
    close() {
      this.dialog = false;
      this.limpiar();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    limpiar() {
      this.search = "";
      this.productos = [];
    },
    activar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put("api/Productos/Activar/" + this.adid, {}, configuracion)
        .then(function (response) {
          me.addmodal = 0;
          me.addaction = 0;
          me.addnombre = "";
          me.adid = "";
          me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    activardesactivarcerrar() {
      this.addmodal = 0;
    },

    obtenerprecioexistencias() {
      if (this.search.length > 1) {
        this.loading = true;
        let me = this;
        let url = "";
        if (this.row == 1) {
          url =
            "api/VST_ExistenciaProductoUbicacion/ListarIngreso/" + this.search;
        } else {
          url =
            "api/VST_ExistenciaProductoUbicacion/ListarIngresoUbi/" +
            "'" +
            this.search +
            "'";
        }
        //me.existenciasuni=0;
        let header = { Authorization: "Bearer " + this.$store.state.token };
        let configuracion = { headers: header };
        axios
          .get(url, configuracion)
          .then(function (response) {
            me.productos = response.data;
            // me.mostrarexistencias();
          })
          .catch(function (error) {
            console.log(error);
            alert("Error al Consultar");
          })
          .finally(() => (this.loading = false));
      } else {
        alert("Complete el Campo de Busqueda");
      }
    },
  },
};
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}
</style>