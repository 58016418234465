<template>
    <v-data-table
      :headers="headers"
      :items="cajeros"
      sort-by="idcajero"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Cajeros</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >

            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Nuevo
              </v-btn>
            
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                  <v-row>

                           <v-col 
                      v-show="editedIndex"
                      cols="12"
                      sm="6"
                      md="12"
                    >

                      <v-text-field 
                        label="Codigo"
                        v-model="editedItem.cajero" 
                         v-on:blur="validadcodigo()"
                        :disabled="campo_bloqueado">
                        
                      ></v-text-field>

                    </v-col >

                    <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.Nombre"
                        label="Nombre"
                      ></v-text-field>

                    </v-col >
                    
                    <v-col 
                      v-show="editedIndex"
                      cols="12"
                      sm="12"
                      md="12"
                    >

                      <v-text-field 
                        label="Clave"
                        v-model="editedItem.clave">
                      ></v-text-field>

                    </v-col >
                    <v-col
                      cols="12"
                      sm="6"
                      md="12"
                      v-show="valida"
                    >
                        <v-text class="red--text" v-for="v in validamensaje" :key="v" v-text="v">                    
                        </v-text>
                      
                    </v-col>
                  
                  </v-row>

                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="Guardar"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="addmodal" max-width="500px">
            <v-card>
              <v-card-title class="headline" v-if="addaction==1"> Desea Activar </v-card-title>
              <v-card-title class="headline" v-if="addaction==2"> Desea Desactivar </v-card-title>
              
              <v-card-text > Estas a Punto de: </v-card-text>
                <span v-if="addaction==1"> Activar </span>
                <span  v-if="addaction==2"> Desactivar</span>
                el item {{addnombre}}
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="activardesactivarcerrar">Cancel</v-btn>
                  <v-btn v-if="addaction==1" color="blue darken-1" text @click="activar">Activar</v-btn>
                  <v-btn v-if="addaction==2" color="blue darken-1" text @click="desactivar">Desactivar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
           
      
      <template v-slot:[`item.actions`]="{ item }">  
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
        <template v-if="item.condicion">
          <v-icon
            small
            
            @click="activardesacticarmostrar(2,item)"
          >
            block
          </v-icon>    
        </template>
        <template v-else>
          <v-icon
            small
            @click="activardesacticarmostrar(1,item)"
          >
            check
          </v-icon>    
        </template>

      </template>

     <template v-slot:[`item.condicion`]="{ item }">
        <div v-if="item.condicion">
          <span class="blue--text">Activo</span>
        </div>
        <div v-else>
          <span class="red--text">Inactivo</span>
        </div>
      </template>



      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="listar"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
</template>


<script>
import axios from 'axios'
export default {

  data: () => ({
    dialog: false,
    search:'',
    dialogDelete: false,
    cajeros:[],
    headers: [
      { text: 'Acciones', value: 'actions', sortable: false },
      {
        text: 'IdCajero',
        align: 'start',
        value: 'idCajero',
      },
      { text: 'Codigo', value: 'cajero' },
      { text: 'Nombre', value: 'nombre' },
      { text: 'Condicion', value: 'condicion' }
    ],
    editedIndex: -1,
    editedItem: {
      idCajero: '',
      cajero: '',
      Nombre: '',
      clave: ''
    },
      valida:0,
      validamensaje:[],
      addmodal:0,
      addaction:0,
      addnombre:'',
      adid:'',
      campo_bloqueado:false
  }),

 computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Cajero' : 'Editando Cajero'
    },
  },

  created () {
    this.listar()
  },

   watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
    methods: {
        listar(){
                let me=this
                let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};
                axios.get('api/acajeros/Listar',configuracion).then(function(response){
                    me.cajeros=response.data;
                }).catch(function(error){
                    console.log(error);
                });
            },

   

    editItem (item) {
      this.editedItem.idCajero = item.idCajero
      this.editedItem.Nombre = item.nombre
      this.editedItem.cajero=item.cajero
      this.editedItem.clave=item.clave
      this.editedIndex=1;
      this.campo_bloqueado=true;
      this.dialog = true

    },

    validadcodigo() {
      let ma = this.editedItem;
      
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios.get("api/Acajeros/BuscarCodigo/" + ma.cajero, configuracion)
        .then(function (response) {
            alert('El Codigo de Cajero ya Existe')    
            ma.cajero=""      
          console.log(response.data.length);
        })
        .catch(function (error) {
          console.log('No Encontrado');
        });
    },



    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false;
      this.limpiar();
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    limpiar () {
      this.editedIndex=-1;
      this.editedItem.Nombre="";
      this.editedItem.idCajero="";
      this.editedItem.cajero="";
      this.editedItem.clave="";
      this.campo_bloqueado=false;
    },

    

    validar () {
      this.valida=0;
      this.validamensaje=[];

      if (this.editedItem.Nombre.length<3 || this.editedItem.Nombre.length>50 )
      {
          this.validamensaje.push("El nombre debe tener mas de 3 Caracteres y Menos de 50 caracteres")
      }

      if(this.editedItem.cajero.length!=3 )
      {
        this.validamensaje.push("El Codigo debe tener 3 Caracteres")
      }


      if (this.validamensaje.length)
      {
        this.valida=1;
      }
      return this.valida;
    },

 activardesacticarmostrar (accion,item) {
      
      this.addmodal=1;
      this.addnombre=item.nombre;
      this.adid=item.idCajero;
      if (accion==1)
      {
        this.addaction=1;
      }
      else if(accion==2) {
        this.addaction=2;
      }
      else{
        this.addmodal=0;
      }
  
    },



activar () {
  let me=this;
  let header={"Authorization":"Bearer "+this.$store.state.token};
  let configuracion={headers:header};
      axios.put('api/Acajeros/Activar/'+this.adid,{},configuracion).then(function(response)
          {
            me.addmodal=0;
            me.addaction=0;
            me.addnombre="";
            me.adid="";
            me.listar();

          }).catch(function(error)
          {
            console.log(error);
          });
  
    },

desactivar () {
  let me=this;
  let header={"Authorization":"Bearer "+this.$store.state.token};
  let configuracion={headers:header};
      axios.put('api/Acajeros/DesActivar/'+this.adid,{},configuracion).then(function(response)
          {
            me.addmodal=0;
            me.addaction=0;
            me.addnombre="";
            me.adid="";
            me.listar();

          }).catch(function(error)
          {
            console.log(error);
          });
  
    },

activardesactivarcerrar () {
      this.addmodal=0;
    },


Guardar () {
      let ma=this;
      if (ma.validar()){
        return; 
      }
      if (this.editedIndex > -1) {
        //actualizar
        let me=this.editedItem;
        let header={"Authorization":"Bearer "+this.$store.state.token};
        let configuracion={headers:header};
        axios.put('api/Acajeros/Actualizar',{
          'idcajero':me.idCajero,
          'Nombre':me.Nombre,
          'clave':me.clave           
          },configuracion).then(function(response)
            {
              ma.close();
              ma.listar();
              ma.limpiar()
            }).catch(function(error)
            {
              console.log(error); 
            });


      } else {
        let me=this.editedItem;
        let header={"Authorization":"Bearer "+this.$store.state.token};
        let configuracion={headers:header};
        axios.post('api/Acajeros/Crear',{
          'cajero':me.cajero,   
          'Nombre':me.Nombre,
          'clave':me.clave   
          },configuracion).then(function(response)
            {
              ma.close()
              ma.listar()
              ma.limpiar()  
            }).catch(function(error)
            {
              console.log(error);
            });
      }   
    },
  }, 
}

</script>