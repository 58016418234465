<template>
    <v-data-table
      :headers="headers"
      :items="Proveedores"
      sort-by="idproveedor"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Proveedores </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="700px"
          >

            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Nuevo
              </v-btn>
            
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                  <v-row>

                      <v-col 
                      v-show="editedIndex"
                      cols="12"
                      sm="6"
                      md="12"
                    >
                      <v-text-field 
                        label="Codigo"
                        v-model="editedItem.proveedor" :disabled="campo_bloqueado"
                        v-on:blur="validadcodigo()">
                        >
                      ></v-text-field>

                    </v-col >

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.Nombre"
                        label="Nombre"
                      ></v-text-field>

                    </v-col >
                    
<v-col
                      cols="6"
                      sm="6"
                      md="6 "
                    >
              <v-select
              v-model="editedItem.Tipo_Documento"
              :items="TDocumentos"
              
              label="Tipo de Documento"
            >
            </v-select>
</v-col>

<v-col
                      cols="6"
                      sm="6"
                      md="6"
                    >            <v-text-field
              v-mask="'####-####-######'"
              v-model="editedItem.numero_documento"
              label="Numero de Documento"
            ></v-text-field>
</v-col>


                  <v-col cols="12" sm="12">
                    <v-select
                      v-model="editedItem.idclasificacion"
                      :items="clasificaciones"
                      label="Clasificacion"
                    >
                    </v-select>
                  </v-col>

                        <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.direccionweb"
                        label="Direccion"
                      ></v-text-field>

                    </v-col >
                    
                       <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                      ></v-text-field>

                    </v-col >

                       <v-col
                      cols="12"
                      sm="6"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.telefono"
                        label="Telefono"
                      ></v-text-field>

                    </v-col >
         
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-show="valida"
                    >
                        <v-text class="red--text" v-for="v in validamensaje" :key="v" v-text="v">                    
                        </v-text>
                      
                    </v-col>
                  
                  </v-row>

                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="Guardar"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="addmodal" max-width="500px">
            <v-card>
              <v-card-title class="headline" v-if="addaction==1"> Desea Activar </v-card-title>
              <v-card-title class="headline" v-if="addaction==2"> Desea Desactivar </v-card-title>
              
              <v-card-text > Estas a Punto de: </v-card-text>
                <span v-if="addaction==1"> Activar </span>
                <span  v-if="addaction==2"> Desactivar</span>
                el item {{addnombre}}
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="activardesactivarcerrar">Cancel</v-btn>
                  <v-btn v-if="addaction==1" color="blue darken-1" text @click="activar">Activar</v-btn>
                  <v-btn v-if="addaction==2" color="blue darken-1" text @click="desactivar">Desactivar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
           
      
      <template v-slot:[`item.actions`]="{ item }">  
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
        <template v-if="item.condicion">
          <v-icon
            small
            
            @click="activardesacticarmostrar(2,item)"
          >
            block
          </v-icon>    
        </template>
        <template v-else>
          <v-icon
            small
            @click="activardesacticarmostrar(1,item)"
          >
            check
          </v-icon>    
        </template>

      </template>

     <template v-slot:[`item.condicion`]="{ item }">
        <div v-if="item.condicion">
          <span class="blue--text">Activo</span>
        </div>
        <div v-else>
          <span class="red--text">Inactivo</span>
        </div>
      </template>



      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="listar"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
</template>


<script>
import axios from 'axios'
export default {

  data: () => ({
    dialog: false,
    dialogcta:false,
    search:'',
    dialogDelete: false,
    Proveedores:[],
    clasificaciones:[],
    cabecera: [
      { text: 'Acciones', value: 'actions', sortable: false },
      {
        text: 'idproveedor',
        align: 'start',
        value: 'idproveedor',
      },
      { text: 'Codigo', value: 'proveedor' },
      { text: 'Nombre', value: 'nombre' },
      { text: 'Condicion', value: 'condicion' }
    ],
    headers: [
      { text: 'Acciones', value: 'actions', sortable: false },
      {
        text: 'idproveedor',
        align: 'start',
        value: 'idproveedor',
      },
      { text: 'Codigo', value: 'proveedor' },
      { text: 'Nombre', value: 'nombre' },
      { text: 'Numero de Identificacion', value: 'numero_documento' },
      { text: 'NClasificacion', value: 'nclasificacion' },
      
      { text: 'Condicion', value: 'condicion' }
    ],
    headerscta: [
      { text: 'Nombre', value: 'text' },
      { text: 'Cuenta', value: 'valor' },
    ],
    TDocumentos: ["DNI", "Pasaporte", "RTN"],

    editedIndex: -1,
    editedItem: {
      Tipo_Documento:'',
      idproveedor: '',
      Nombre: '',
      proveedor:'',
      direccionweb:'',
      email:'',
      telefono:'',
      numero_documento:'',
      idclasificacion:'',
    },

      valida:0,
      validamensaje:[],
      addmodal:0,
      addaction:0,
      addnombre:'',
      adid:'',
      campo_bloqueado:false
  }),

 computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Proovedor' : 'Editando Proveedor'
    },
  },

  created () {
    this.listar()
    this.Selectclasificacion()
    
  },

   watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
    methods: {
        listar(){
                let me=this
                let header={"Authorization":"Bearer "+this.$store.state.token};
                let configuracion={headers:header};
                axios.get('api/Proveedores/Listar',configuracion).then(function(response){
                    me.Proveedores=response.data;  
                }).catch(function(error){
                    console.log(error);
                });
            },

             ListarCtaContables() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      var clasificacionesaray = [];
      axios
        .get("api/CtaContables/listar", configuracion)
        .then(function (response) {
          clasificacionesaray = response.data;
          clasificacionesaray.map(function (x) {
            me.CuentasContables.push({ 
              "text": x.ctacontable, 
              "valor": x.ctacontable });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
             },


    Selectclasificacion() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      var clasificacionaray = [];
      axios
        .get("api/ClasiProveedores/Select", configuracion)
        .then(function (response) {
          clasificacionaray = response.data;
          clasificacionaray.map(function (x) {
            me.clasificaciones.push({ text: x.nombre, value: x.idclasificacion});
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    editItem (item) {
      
      this.editedItem.idproveedor = item.idproveedor;
      this.editedItem.proveedor = item.proveedor;
      this.editedItem.Nombre = item.nombre;
      this.editedItem.direccionweb = item.direccionweb; 
      this.editedItem.email = item.email;
      this.editedItem.telefono = item.telefono;
      this.editedItem.Tipo_Documento = item.tipo_documento;
      this.editedItem.numero_documento = item.numero_documento;
      this.editedItem.idclasificacion = item.idclasificacion;
      this.editedIndex=1;
      this.campo_bloqueado=true;
      this.dialog = true

    },

    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },
      
      validadcodigo() {
      let me = this;
      let ma= this.editedItem;
      let clientes=[];
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/Proveedores/BuscarCodigo/" + this.editedItem.proveedor, configuracion)
        .then(function (response) {
            ma.proveedor='';
          alert('Codigo de Proveedor ya Existe')
        })
        .catch(function (error) {
          console.log();
        });
    },


    close () {
      this.dialog = false;
      this.limpiar();
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    limpiar () {
      this.editedIndex=-1;
      this.editedItem.Nombre="";
      this.editedItem.idproveedor="";
      this.editedItem.idclasificacion ="";
      this.editedItem.proveedor="";
      this.editedItem.direccionweb="";
      this.editedItem.email="";
      this.editedItem.numero_documento="";
      this.editedItem.telefono="";
      this.campo_bloqueado=false;
      this.validamensaje=[];
    },

    

    validar () {
      this.valida=0;
      this.validamensaje=[];

     if (this.editedItem.proveedor.length<3 || this.editedItem.proveedor.length>5 )
      {
          this.validamensaje.push("El Codigo debe tener mas de 3 Caracteres y Menos de 6 caracteres")
      }


      if (this.editedItem.Tipo_Documento.length<1 )
      {
          this.validamensaje.push("Seleccione un Tipo de documento")
      }
      
      if (this.editedItem.idclasificacion.length<1 )
      {
          this.validamensaje.push("Seleccione una Clasificacion Valida")
      }
      
      if (this.editedItem.numero_documento.length<5 )
      {
          this.validamensaje.push("El numero de documento debe tener mas  de  5 Caracteres ")
      }

      if (this.editedItem.Nombre.length<3 || this.editedItem.Nombre.length>60 )
      {
          this.validamensaje.push("El nombre debe tener mas de 3 Caracteres y Menos de 60 caracteres")
      }

      if (this.editedItem.direccionweb.length<3 || this.editedItem.Nombre.length>99 )
      {
          this.validamensaje.push("La Direccion debe tener mas de 3 Caracteres y Menos de 90 caracteres")
      }

      var emailc = this.editedItem.email;
      this.pruebaemail(emailc);


      if (this.editedItem.telefono.length < 5) {
        this.validamensaje.push(
          "*El numero de telefono tener mas de 5 Caracteres."
        );
      }

      if (this.validamensaje.length)
      {
        this.valida=1;
      }
      return this.valida;
    },

   pruebaemail(valor) {
      var re = /^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
      if (!re.exec(valor)) {
        this.validamensaje.push("*El Email no es valido");
      }
    },
 activardesacticarmostrar (accion,item) {
      
      this.addmodal=1;
      this.addnombre=item.nombre;
      this.adid=item.idproveedor;
      if (accion==1)
      {
        this.addaction=1;
      }
      else if(accion==2) {
        this.addaction=2;
      }
      else{
        this.addmodal=0;
      }
  
    },



activar () {
  let me=this;
  let header={"Authorization":"Bearer "+this.$store.state.token};
  let configuracion={headers:header};
      axios.put('api/Proveedores/Activar/'+this.adid,{},configuracion).then(function(response)
          {
            me.addmodal=0;
            me.addaction=0;
            me.addnombre="";
            me.adid="";
            me.listar();

          }).catch(function(error)
          {
            console.log(error);
          });
  
    },

desactivar () {
  let me=this;
  let header={"Authorization":"Bearer "+this.$store.state.token};
  let configuracion={headers:header};
      axios.put('api/Proveedores/DesActivar/'+this.adid,{},configuracion).then(function(response)
          {
            me.addmodal=0;
            me.addaction=0;
            me.addnombre="";
            me.adid="";
            me.listar();

          }).catch(function(error)
          {
            console.log(error);
          });
  
    },

activardesactivarcerrar () {
      this.addmodal=0;
    },


Guardar () {
      let ma=this;
      if (ma.validar()){
        return; 
      }

      if (this.editedIndex > -1) {
        //actualizar
        let me=this.editedItem;
        let header={"Authorization":"Bearer "+this.$store.state.token};
        let configuracion={headers:header};
        axios.put('api/Proveedores/Actualizar',{
          'idproveedor':me.idproveedor,
          'nombre':me.Nombre,
          'direccionweb':me.direccionweb,
          'email':me.email,
          'telefono':me.telefono,
          'tipo_documento':me.Tipo_Documento,
          'numero_documento':me.numero_documento,
          'idclasificacion':me.idclasificacion,
          },configuracion).then(function(response)
            {
              ma.close();
              ma.listar();
              ma.limpiar()
            }).catch(function(error)
            {
              console.log(error);
            });


      } else {
         let me=this.editedItem;
        let header={"Authorization":"Bearer "+this.$store.state.token};
        let configuracion={headers:header};
        axios.post('api/Proveedores/Crear',{
          'nombre':me.Nombre,
          'proveedor':me.proveedor,
          'direccionweb':me.direccionweb,
          'email':me.email,
          'telefono':me.telefono,
          'tipo_documento':me.Tipo_Documento,
          'numero_documento':me.numero_documento,
          'idclasificacion':me.idclasificacion,
          },configuracion).then(function(response)
            {
              ma.close()
              ma.listar()
              ma.limpiar()  
            }).catch(function(error)
            {
              console.log(error);
            });
      }   
    },
  }, 
}

</script>