<template>
  <v-data-table
    :headers="headers"
    :items="seriesdocumentos"
    sort-by="idseriedocumento"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Documentos CAI</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="1000px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Nuevo
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col v-show="editedIndex" cols="12" sm="4" md="4">
                    <v-text-field
                      label="serie"
                      v-model="editedItem.serie"
                      v-on:blur="validadcodigo()"
                      :disabled="campo_bloqueado"
                    >
                      ></v-text-field
                    >
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-select
                      v-model="editedItem.tipo"
                      :items="tipodoc"
                      :disabled="campo_bloqueado"
                      label="Tipo"
                    >
                    </v-select>
                  </v-col>

                  <v-col v-show="editedIndex" cols="12" sm="4" md="4">
                    <v-text-field
                      label="Prefijo"
                      v-model="editedItem.prefijo"
                      :disabled="campo_bloqueado"
                    >
                      ></v-text-field
                    >
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.cai"
                      label="#Cai"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.rdesde"
                      label="Rango Inicial"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.rhasta"
                      label="Rango Final"
                    ></v-text-field>
                  </v-col>

                  <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="datedesde"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="datedesde"
            label="Fecha de Recepcion"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datedesde"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(datedesde)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>


<v-col
      cols="12"
      sm="6"
      md="4"
    >
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="datehasta"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="datehasta"
            label="Fecha Limite de Emision"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datehasta"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu2.save(datehasta)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>

                 


                  <v-col cols="12" sm="6" md="12" v-show="valida">
                    <v-text
                      class="red--text"
                      v-for="v in validamensaje"
                      :key="v"
                      v-text="v"
                    >
                    </v-text>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="dialogespera"
                :loading="dialogespera"
                @click="(dialogespera = true), Guardar()"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="addmodal" max-width="500px">
          <v-card>
            <v-card-title class="headline" v-if="addaction == 1">
              Desea Activar
            </v-card-title>
            <v-card-title class="headline" v-if="addaction == 2">
              Desea Desactivar
            </v-card-title>

            <v-card-text> Estas a Punto de: </v-card-text>
            <span v-if="addaction == 1"> Activar </span>
            <span v-if="addaction == 2"> Desactivar</span>
            el item {{ addnombre }}
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="activardesactivarcerrar"
                >Cancel</v-btn
              >
              <v-btn
                v-if="addaction == 1"
                color="blue darken-1"
                text
                @click="activar"
                >Activar</v-btn
              >
              <v-btn
                v-if="addaction == 2"
                color="blue darken-1"
                text
                @click="desactivar"
                >Desactivar</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> edit </v-icon>
      <template v-if="item.condicion">
        <v-icon small @click="activardesacticarmostrar(2, item)">
          block
        </v-icon>
      </template>
      <template v-else>
        <v-icon small @click="activardesacticarmostrar(1, item)">
          check
        </v-icon>
      </template>
    </template>

    <template v-slot:[`item.condicion`]="{ item }">
      <div v-if="item.condicion">
        <span class="blue--text">Activo</span>
      </div>
      <div v-else>
        <span class="red--text">Inactivo</span>
      </div>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="listar"> Reset </v-btn>
    </template>
  </v-data-table>
</template>


<script>
import axios from "axios";

export default {
  data: () => ({
    dialog: false,
    menu: false,
    menu2: false,
    search: "",
    dialogDelete: false,
    seriesdocumentos: [],
    headers: [
      { text: "Acciones", value: "actions", sortable: false },
      {
        text: "Id Serie Documento",
        value: "idseriedocumento"
      },
      { text: "Codigo", value: "serie" },
      { text: "Tipo", value: "tipo" },
      { text: "Prefijo", value: "prefijo" },
      { text: "Cai", value: "cai" },
      { text: "Rdesde", value: "rdesde" },
      { text: "Rhasta", value: "rhasta" },
      { text: "FRecepcion", value: "fRecepcion" },
      { text: "FlimiteEmision", value: "flimiteEmision" },
      { text: "Condicion", value: "condicion" },
    ],

    tipodoc: [
      { text: "Factura", value: "01", sortable: false },
      { text: "Comprobante Retencion", value: "05" },
      { text: "Nota de Credito", value: "06" },
      { text: "Nota de Debito", value: "07" },
      { text: "Boleta de Compra", value: "11" },
    ],

    dialogespera: false,
    datehasta: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    datedesde: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    editedIndex: -1,
    editedItem: {
      idseriedocumento: "",
      serie: "",
      tipo: "",
      prefijo: "",
      cai: "",
      rdesde: "",
      rhasta: "",
      fRecepcion: "",
      flimiteEmision: "",
    },
    valida: 0,
    validamensaje: [],
    addmodal: 0,
    addaction: 0,
    addnombre: "",
    adid: "",
    campo_bloqueado: false,
  
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Documentos CAI"
        : "Editando Documentos CAI";
    },
  },

  created() {
    this.listar();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    listar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/SerieDocumento/Listar", configuracion)
        .then(function (response) {
          me.seriesdocumentos = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    Esperarcargar() {
      this.dialogespera = true;
    },
    Esperartermina() {
      this.dialogespera = null;
    },

    editItem(item) {
      this.editedItem.idseriedocumento = item.idseriedocumento;
      this.editedItem.serie = item.serie;
      this.editedItem.tipo = item.tipo;
      this.editedItem.prefijo = item.prefijo;
      this.editedItem.cai = item.cai;
      this.editedItem.rdesde = item.rdesde;
      this.editedItem.rhasta = item.rhasta;
      this.editedItem.fRecepcion = item.fRecepcion;
      this.editedItem.flimiteEmision = item.flimiteEmision;
      var dated = new Date(item.fRecepcion);
      var datehas = new Date(item.flimiteEmision);
      let datec = new Date(dated - dated.getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      let dhasta = new Date(datehas - datehas.getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.datedesde = datec;
      this.datehasta = dhasta;      

      this.editedIndex = 1;
      this.campo_bloqueado = true;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.limpiar();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    limpiar() {
      this.editedIndex = -1;
      this.editedItem.idseriedocumento = "";
      this.editedItem.serie = "";
      this.editedItem.tipo = "";
      this.editedItem.prefijo = "";
      this.editedItem.cai = "";
      this.editedItem.rdesde = "";
      this.editedItem.rhasta = "";
      this.datehasta= new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      this.datedesde= new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      this.campo_bloqueado = false;
      this.Esperartermina();
    },

    validar() {
      this.valida = 0;
      this.validamensaje = [];

      if (this.editedItem.cai.length < 3 ||this.editedItem.cai.length > 38
      ) {
        this.validamensaje.push(
          "El CAI debe tener mas de 3 Caracteres y Menos de 38 caracteres,"
        );
      }

      if (this.editedItem.serie.length < 1) {
        this.validamensaje.push("La Serie debe tener al menos 1 Caracteres,");
      }

      if (this.editedItem.prefijo.length != 2) {
        this.validamensaje.push("La Prefijo debe tener 2 Caracteres,");
      }

      if (this.editedItem.rdesde.length < 1) {
        this.validamensaje.push(
          "El Rango Desde debe tener 8 Caracteres,"
        );
      }
  
      if (this.editedItem.rhasta.length != 8) {
        this.validamensaje.push(
          "El Rango Hasta debe tener 8 Caracteres,"
        );
       }

      if (this.validamensaje.length) {
        this.valida = 1;
      }
      this.Esperartermina();
      return this.valida;
    },

    activardesacticarmostrar(accion, item) {
      this.addmodal = 1;
      this.addnombre = item.cai;
      this.adid = item.idseriedocumento;
      if (accion == 1) {
        this.addaction = 1;
      } else if (accion == 2) {
        this.addaction = 2;
      } else {
        this.addmodal = 0;
      }
    },

    activar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put("api/SerieDocumento/Activar/" + this.adid, {}, configuracion)
        .then(function (response) {
          me.addmodal = 0;
          me.addaction = 0;
          me.addnombre = "";
          me.adid = "";
          me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    desactivar() {
      let me = this;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put("api/SerieDocumento/DesActivar/" + this.adid, {}, configuracion)
        .then(function (response) {
          me.addmodal = 0;
          me.addaction = 0;
          me.addnombre = "";
          me.adid = "";
          me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    activardesactivarcerrar() {
      this.addmodal = 0;
    },

    validadcodigo() {
      this.Esperarcargar()
      let ma = this.editedItem;
      let header = { Authorization: "Bearer " + this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("api/SerieDocumento/BuscarCodigo/" + ma.serie, configuracion)
        .finally(() => this.Esperartermina())
        .then(function (response) {
          alert("El Codigo ya Existe");
          ma.serie = "";
          this.Esperartermina()
          console.log(response.data.length);
        })
        .catch(function (error) {
          console.log("No Encontrado");
        });
    },

    Guardar() {
      this.Esperarcargar();
      let ma = this;
      if (ma.validar()) {
        return;
      }

      if (this.editedIndex > -1) {
        //actualizar
        let me = this.editedItem;
        let ma = this;
        let header = { Authorization: "Bearer " + this.$store.state.token };
        let configuracion = { headers: header };
        alert(me.rdesde)
        axios
          .put(
            "api/SerieDocumento/Actualizar",
            {
              'Idseriedocumento': me.idseriedocumento,
              'Tipo': me.tipo,
              'Prefijo': me.prefijo,
              'Cai': me.cai,
              'Rdesde': me.rdesde,
              'Rhasta': me.rhasta,
              'FRecepcion': ma.datedesde,
              'FlimiteEmision': ma.datehasta,
            },
            configuracion
          )

          .then(function (response) {
            ma.close();
            ma.listar();
            ma.limpiar();
          })
          .finally(() => this.Esperartermina())
          .catch(function (error) {
            console.log(error);
            alert("Registo no Actualizado");
          });
      } else {
        let me = this.editedItem;
        let ma = this;
        let header = { Authorization: "Bearer " + this.$store.state.token };
        let configuracion = { headers: header };
        axios
          .post(
            "api/SerieDocumento/Crear",
            {
             'Tipo': me.tipo,
              'Prefijo': me.prefijo,
              'Cai': me.cai,
              'Rdesde': me.rdesde,
              'Rhasta': me.rhasta,
              'FRecepcion': ma.datedesde,
              'FlimiteEmision': ma.datehasta,
            },
            configuracion
          )
          .finally(() => this.Esperartermina())
          .then(function (response) {
            ma.close();
            ma.listar();
            ma.limpiar();
          })
          .catch(function (error) {
            console.log(error);
            alert("Registo no Actualizado");
          });
      }
    },
  },
};
</script>